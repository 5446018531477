/* Drag and Drop */

.draggable {
  touch-action: none;
  /* touch-action: manipulation; */
  user-select: none;
  /* -webkit-user-drag: none; */
}

.draggable.dragging {
  opacity: 0.25;
}

.droppable {
  /* width: 100px;
  height: 80px;
  border-radius: 6px;
  display: inline-block;
  padding: 20px;
  margin-left: 20px;
  border: 2px dotted gray; */
  white-space: pre-wrap;
}

.droppable.target {
  border: 1px solid blue;
  background-color: #0080ff3f;
}

