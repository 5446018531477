/* .table-wrapper {
  width: 90%;

  padding: 1rem 7rem;
} */

.non-tip-actions .table-wrapper,
.golden-moments .table-wrapper {
  width: 100%;
  padding-top: 60px;
}

.transmissions .table-wrapper {
  width: 100%;  
}

.table {
  display: block;
  /* overflow: hidden; */
  /* table-layout: fixed; */
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  position: relative;
  /* white-space: nowrap; */
  /* width: 100em; */
  /* max-width: 80%; */
  margin: auto;
  /* table-layout: auto; */
  /* overflow-x: auto; */
  height: 1040px;
}

.table tr {
  width: 100%;
  display: flex;
  /* height: 70px; */
  table-layout: fixed;
}

.table thead {
  background-color: #ccc;
  color: #222;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
}

.table th,
.table td {
  padding: 0.8rem;
}

.table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 964px;
  position: absolute;
  scrollbar-color: auto;
  width: 100%;
}

/* Golden Moments specific table styles */
.golden-moments .table {
  /* width: 100%;
  table-layout: fixed; */
}

.golden-moments .table tbody {
  /* display: block;
  height: 964px;
  overflow-y: auto;
  width: 100%; */
}

.golden-moments .table thead,
.golden-moments .table tbody tr {
  /* display: table;
  width: 100%;
  table-layout: fixed; */
}

.golden-moments .table thead,
.non-tip-actions .table thead,
.transmissions .table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}

.table td {
  border-bottom: 0.5px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: auto;
  border-right: 0.5px solid #ddd;
  font-weight: 400;
}

.table tbody tr:hover {
  background-color: #eee;
}

.btn-add {
  width: auto;
  height: 70px;
  border-radius: 35px;
  background-color: var(--dark-accept-color);
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  font-family: var(--default-font);
}

/* GOLDEN MOMENTS COLUMNS */
.gm_name {
  /* width: 20%; */
  flex: 0 0 calc(20% - 20px);
  width: calc(20% - 20px);
}

.gm_description {
  flex: 0 0 calc(35% - 108px);
  width: calc(35% - 108px);
}

.gm_actions {
  flex: 0 0 8%;
  width: 8%;
}
.gm_actions span {
  gap: 10px;
}
.gm_when {
  flex: 0 0 12%;
  text-align: center;
  width: 12%;
}
.tags-exist .gm_department {
  flex: 0 0 12%;
  text-align: center;
  position: relative;
  width: 12%;
}
.gm_department {
  flex: 0 0 25%;
  text-align: center;
  position: relative;
  width: 25%;
}
.tags-exist .gm_tags {
  flex: 0 0 12%;
  text-align: center;
  position: relative;
  width: 12%;
}
.form-golden-moments__table tbody .gm_department,
.form-golden-moments__table tbody .tags-exist .gm_tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

tbody .gm_department .label,
.tags-exist .gm_tags .label {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-primary-40);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 40px;
}

tbody .gm_department .label.expanded,
.tags-exist .gm_tags .label.expanded {
  border-radius: 20px;
  width: auto;
  padding: 0 16px;
  overflow-x: scroll;
  white-space: nowrap;
}
tbody .gm_department .label.expanded::-webkit-scrollbar,
.tags-exist .gm_tags .label.expanded::-webkit-scrollbar {
  display: none;
}
.gm_department .more-departments,
.tags-exist .gm_tags .more-departments {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f0f0f0;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 12px;
  transition: background-color 0.2s ease;
}
.gm_department .more-departments:hover,
.tags-exist .gm_tags .more-departments:hover {
  background-color: #e0e0e0;
}
.gm_department .departments-dropdown,
.tags-exist .gm_tags .departments-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 10;
  min-width: 150px;
}
.gm_department .departments-dropdown.show,
.tags-exist .gm_tags .departments-dropdown.show {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.gm_department .departments-dropdown .label,
.tags-exist .gm_tags .departments-dropdown .label {
  border-radius: 4px;
  width: 100%;
  height: auto;
  padding: 8px 16px;
  text-align: left;
}
@media (max-width: 1980px) {
  tbody .gm_department .label:nth-child(n + 4),
  .tags-exist .gm_tags .label:nth-child(n + 4) {
    display: none;
  }

  .gm_department .more-departments,
  .tags-exist .gm_tags .more-departments {
    display: flex;
  }
  .form-golden-moments__table tbody .gm_when {
    font-size: clamp(18px, 2vw, 28px);
  }
  .golden-moments .table tr {
    font-size: clamp(20px, 2vw, 28px);
  }
}
@media (min-width: 1981px) {
  .gm_department .more-departments,
  .tags-exist .gm_tags .more-departments {
    display: none;
  }

  tbody .gm_department .label,
  .tags-exist .gm_tags .label {
    width: 48px;
    height: 48px;
    font-size: 16px;
  }

  tbody .gm_department .label.expanded,
  .tags-exist .gm_tags .label.expanded {
    padding: 0 24px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .form-golden-moments__table tbody .gm_when {
    font-size: clamp(14px, 16px, 18px);
  }
}
/* END OF GOLDEN MOMENTS COLUMNS */

/* NON-TIP ACTIONS COLUMNS */

.nta_index {
  flex: 0 0 5%;
  width: 5%;
  text-align: center;
}

.nta_description {
  flex: 0 0 22%;
  width: 22%;
}

.nta_due {
  flex: 0 0 10%;
  width: 10%;
  text-align: center;
}

.nta_name {
  flex: 0 0 16%;
  width: 16%;
}

.nta_status {
  flex: 0 0 10%;
  width: 10%;
  text-align: center;
}

.nta_actions {
  flex: 0 0 16%;
  width: 16%;
}

.nta_department {
  flex: 0 0 12%;
  text-align: center;
  position: relative;
  width: 12%;
}
.tags-exist .nta_index {
  flex: 0 0 3%;
  width: 3%;
  text-align: center;
}

.tags-exist .nta_description {
  flex: 0 0 22%;
  width: 22%;
}

.tags-exist .nta_due {
  flex: 0 0 10%;
  width: 10%;
  text-align: center;
}

.tags-exist .nta_name {
  flex: 0 0 16%;
  width: 16%;
}

.tags-exist .nta_status {
  flex: 0 0 10%;
  width: 10%;
  text-align: center;
}

.tags-exist .nta_actions {
  flex: 0 0 10%;
  width: 10%;
}

.tags-exist .nta_department {
  flex: 0 0 10%;
  text-align: center;
  position: relative;
  width: 10%;
}
.tags-exist .nta_tags {
  flex: 0 0 9%;
  text-align: center;
  position: relative;
  width: 9%;
}
.form-non-tip-actions__table tbody .nta_department,
.form-non-tip-actions__table tbody .tags-exist .nta_tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

tbody .nta_department .label,
.tags-exist .nta_tags .label {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-primary-40);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 40px;
}

tbody .nta_department .label.expanded,
.tags-exist .nta_tags .label.expanded {
  border-radius: 20px;
  width: auto;
  padding: 0 16px;
}

.nta_department .more-departments,
.tags-exist .nta_tags .more-departments {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 12px;
  transition: background-color 0.2s ease;
}
.nta_department .more-departments:hover,
.tags-exist .nta_tags .more-departments:hover {
  background-color: #e0e0e0;
}
.nta_department .departments-dropdown,
.tags-exist .nta_tags .departments-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 10;
  min-width: 150px;
}
.nta_department .departments-dropdown.show,
.tags-exist .nta_tags .departments-dropdown.show {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.nta_department .departments-dropdown .label,
.tags-exist .nta_tags .departments-dropdown .label {
  border-radius: 4px;
  width: 100%;
  height: auto;
  padding: 8px 16px;
  text-align: left;
}
@media (max-width: 1980px) {
  tbody .nta_department .label:nth-child(n + 4),
  .tags-exist .nta_tags .label:nth-child(n + 4) {
    display: none;
  }

  .nta_department .more-departments,
  .tags-exist .nta_tags .more-departments {
    display: flex;
  }
  .form-non-tip-actions__table tbody .nta_when {
    font-size: clamp(18px, 2vw, 28px);
  }
  .non-tip-actions .table tr {
    font-size: clamp(20px, 2vw, 28px);
  }
}
@media (min-width: 1981px) {
  .nta_department .more-departments,
  .tags-exist .nta_tags .more-departments {
    display: none;
  }

  tbody .nta_department .label,
  .tags-exist .nta_tags .label {
    width: 48px;
    height: 48px;
    font-size: 16px;
  }

  tbody .nta_department .label.expanded,
  .tags-exist .nta_tags .label.expanded {
    padding: 0 24px;
    font-size: 16px;
  }
}
/* END OF NON-TIP ACTIONS COLUMNS */


/* TRANSMISSIONS COLUMNS */

.trsnmsn_index {
  flex: 0 0 4%;
  width: 4%;
  text-align: center;
}

.trsnmsn_text {
  flex: 0 0 36%;
  width: 36%;
}

.trsnmsn_due {
  flex: 0 0 11%;
  width: 11%;
  text-align: center;
}

.trsnmsn_person {
  flex: 0 0 17%;
  width: 17%;
}

.trsnmsn_status {
  flex: 0 0 9%;
  width: 9%;
  text-align: center;
}

.trsnmsn_actions {
  flex: 0 0 9%;
  width: 9%;
}

.trsnmsn_department {
  flex: 0 0 15%;
  text-align: center;
  position: relative;
  width: 15%;
}

.form-transmissions__table tbody .trsnmsn_department {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

tbody .trsnmsn_department .label {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-primary-40);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 40px;
}

tbody .trsnmsn_department .label.expanded {
  border-radius: 20px;
  width: auto;
  padding: 0 16px;
}

.trsnmsn_department .more-departments {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 12px;
  transition: background-color 0.2s ease;
}
.trsnmsn_department .more-departments:hover {
  background-color: #e0e0e0;
}
.trsnmsn_department .departments-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 10;
  min-width: 150px;
}
.trsnmsn_department .departments-dropdown.show {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.trsnmsn_department .departments-dropdown .label {
  border-radius: 4px;
  width: 100%;
  height: auto;
  padding: 8px 16px;
  text-align: left;
}
@media (max-width: 1980px) {
  tbody .trsnmsn_department .label:nth-child(n + 4) {
    display: none;
  }

  .trsnmsn_department .more-departments {
    display: flex;
  }
  .form-transmissions__table tbody .trsnmsn_when {
    font-size: clamp(18px, 2vw, 28px);
  }
  .transmissions .table tr {
    font-size: clamp(20px, 2vw, 28px);
  }
}
@media (min-width: 1981px) {
  .trsnmsn_department .more-departments {
    display: none;
  }

  tbody .trsnmsn_department .label {
    width: 48px;
    height: 48px;
    font-size: 16px;
  }

  tbody .trsnmsn_department .label.expanded {
    padding: 0 24px;
    font-size: 16px;
  }
}
/* END OF TRANSMISSIONS COLUMNS */

.label {
  border-radius: 3px;
  padding: 0.3rem;
  color: var(--black-color);
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actions svg {
  cursor: pointer;
}

.delete-btn {
  color: #e10d05;
}

.expanded {
  width: 100%;
}
.status {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 24px;
}
.status.archived {
  background-color: var(--gray-color);
  color: #fff;
}
.status.completed {
  background-color: var(--accept-color);
  color: #fff;
}
.status.late {
  background-color: var(--danger-color);
  color: #fff;
}
.status.inProgress {
  background-color: var(--yellow-color);
  color: #fff;
}
.modal-container .status-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.modal-container .status-options.hidden {
  display: none;
}

.modal-container .field-label-hidden {
  display: none;
}

.modal-container .status-option {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 1em;
}

.modal-container .status-option.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
.modal-container .status-option.not-selected {
  background-color: #f0f0f0;
  color: #333;
  border-color: #ccc;
}
.modal-container .status-option:hover {
  background-color: #e0e0e0;
}

/* START PATIENT CENTRICITY */

.patient-centricity {
  width: 100%;
}

.patient-centricity h2 {
  text-align: center;
}

.patient-centricity textarea:focus {
  outline: none;
}

.patient-centricity textarea {
  background-color: #fff;
}

.patient-centricity button .edit-controls {
  background-color: transparent;
}

.patient-centricity .form button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 30px;
  padding: 1rem 1rem;
  overflow: hidden;
}

.patient-centricity .edit-submit-button {
  color: var(--dark-accept-color);
}

.patient-centricity .edit-cancel-button {
  color: var(--danger-color);
}

.patient-centricity .input-edit-icon {
  cursor: pointer;
}

.patient-centricity .form__content {
  width: calc(100% - 46px);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  border: 3px solid var(--black-color);
  height: 1120px;
  margin-top: 1em;
  /* padding: 1rem 3rem; */
}

.patient-centricity .form__content .form {
  display: flex;
  align-items: flex-start;
  column-gap: 50px;
  width: 100%;
}

.form-col {
  flex: 1 1 100%;
}

@media (max-width: 811px) {
  .patient-centricity {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .patient-centricity .form__content {
    width: 100%;
    padding: 3rem 0;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
  }

  .patient-centricity .form textarea {
    min-height: 200px;
  }
}

.patient-centricity .form .patient-centricity-row {
  /* width: 500px; */
  word-break: break-word;
  white-space: normal;
  font-family: var(--default-font);
  font-size: 40px;
  line-height: 160%;
  letter-spacing: 0px;
  font-weight: normal;
}

.patient-centricity .form label {
  display: block;
  text-align: center;
  font-family: var(--default-font);
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: 400;
}

.patient-centricity .form textarea {
  padding: 0.8em 0;
  min-height: 950px;
  font-family: var(--default-font);
  font-size: 40px;
  line-height: 160%;
  letter-spacing: 0px;
  font-weight: normal;
}

.patient-centricity .input-edit-icon {
  /* padding: 17px; */
  background-color: unset;
  /* color: #fff; */
  border: 0px;
  cursor: pointer;
  opacity: 0.1;
}

.patient-centricity .input-edit-icon:hover,
.patient-centricity.input-edit-icon:active,
.patient-centricity .input-edit-icon:hover {
  opacity: 0.9;
}

/* END PATIENT CENTRICITY */

/* Celebration Animation Styles */
@keyframes sway-left {
  0% {
    transform: translate(0, -10vh) rotate(0deg);
  }
  25% {
    transform: translate(-15vw, 25vh) rotate(-90deg);
  }
  50% {
    transform: translate(-5vw, 50vh) rotate(-180deg);
  }
  75% {
    transform: translate(-10vw, 75vh) rotate(-270deg);
  }
  100% {
    transform: translate(-20vw, 150vh) rotate(-360deg);
    opacity: 0;
  }
}

@keyframes sway-right {
  0% {
    transform: translate(0, -10vh) rotate(0deg);
  }
  25% {
    transform: translate(15vw, 25vh) rotate(90deg);
  }
  50% {
    transform: translate(5vw, 50vh) rotate(180deg);
  }
  75% {
    transform: translate(10vw, 75vh) rotate(270deg);
  }
  100% {
    transform: translate(20vw, 150vh) rotate(360deg);
    opacity: 0;
  }
}

.celebration-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  pointer-events: none;
  z-index: 9999;
}

.celebration-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: #ffd700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fade-out 4s forwards;
  z-index: 10000;
}

.confetti {
  position: fixed;
  width: 12px;
  height: 12px;
  opacity: 1;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.36, 0, 0.66, -0.56);
}

.confetti-left {
  animation-name: sway-left;
}

.confetti-right {
  animation-name: sway-right;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  20% {
    transform: translate(-50%, -50%) scale(1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
