.huddle-board-content-metric {
  /* flex: 1 1 50%; */
  width: 872px;
}

.huddle-board-content-metric.sponsor-report-metric {
  width: 600px;
}

.huddle-board-content-metric.team-pulse-check-metric {
  width: 600px;
}

.control-metrics-data-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.control-metrics-data {
  background-color: var(--gray-color);
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 20px;
}
.control-metrics-data h3 {
  font-size: 40px;
  font-weight: normal;
  margin: 0px 0px 16px 0px;
  padding: 0;
  line-height: 140%;
  letter-spacing: 0px;
}
.control-metrics-data span {
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.2px;
  font-weight: normal;
}
.huddle-board-content-graph {
  background-color: var(--gray-color);
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  height: 350px;
  width: auto;
  padding: 16px;
}

.color-box {
  width: 20px;
  height: 20px;
  box-shadow: 2px 2px 3px #111;
  display: inline-block;
  margin-right: 10px;
}

@media (max-width: 1920px) {
  /* START Metrica Mobile */
  .control-metrics-data-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .control-metrics-data {
    background-color: var(--gray-color);
    border-radius: 15px;
    padding: 16px;
    margin-bottom: 20px;
  }
  .control-metrics-data h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0px 0px 16px 0px;
    padding: 0;
    line-height: 140%;
    letter-spacing: 0px;
  }
  .control-metrics-data span {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.2px;
    font-weight: normal;
  }

  .huddle-board-content-graph {
    height: 200px;
  }
  /* END Metrica Mobile */
}
