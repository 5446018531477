.tag-form-page {
}
.tag-form-page .project-list {
}
.tag-form-page .project-form__heading {
}

.tag-form-page .action-btn {
}

.tag-form-page .bg {
}
.tag-form-page .primary-blue-bg {
}
.tag-form-page .btn__image {
}
.tag-form-page .list {
  margin-top: 20px;
}
.tag-form-page .label {
}

.tag-form-page .error {
}
.tag-form-page .circle-picker {
  margin-bottom: 40px !important;
}
.tag-form-page .form-actions {
}
.tag-form-page .btn {
}
.tag-form-page .save-btn {
}
