/* .project-list .list {
  margin-right: 40px;
}

.header-link {
  color: #007fff;
  font-weight: 400;
}

.header-link:hover {
  color: #62b0fe;
}

.project-list .no-projects {
  margin-top: 40px;
  max-width: 634px;
  padding: 20px;

  border-radius: 10px;
  border: 3px dashed gray;
}
 */
.project__search {
  position: relative;
  width: 100%;
  max-width: 680px;
  min-width: 200px;
  flex: 1 0 50%;
}
.project-list {
  /* height: 100%; */
  /* 
  flex: 1 1 50%; */
  grid-column: span 2;
  padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 6px #0000001a;
  border-radius: 32px;
}
.project-row.opened .project-list {
  height: auto;
}
.project-list.only-child {
  margin: 0px 40px;
}
.project-list h2,
.users__heading h2 {
  font-size: 68px;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--main-black-color);
}

.header-link {
  border: none;
}
.project-list .project-list__image {
  filter: var(--primary-primary-30-filter);
}
.header-link .project-list__image {
  display: inline-block;
  margin-right: 24px;
  width: 56px;
  height: 56px;
}
.project-list__title {
  /* margin-right: 32px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.project-list__title h2 {
  margin: 0;
}
.project-list__title-h2 {
  flex: 1 0 30%;
}

.project-list__append {
  background-color: var(--primary-primary-80);
}
.project-list__title-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 32px;
  flex: 1 1 auto;
}
.project-list__append:hover,
.project-list__append:active {
  background-color: var(--general-general-70);
}
.project-list__append .project-list__image {
  width: 56px;
  height: 56px;
}
.project-list__append .project-list__image img {
  width: 100%;
  height: 100%;
  filter: var(--primary-primary-30-filter);
  object-fit: contain;
}
.list {
  /* 
  width: 100%; */
  height: 1344px;
  overflow-y: scroll;
  border-radius: 10px;
  padding-right: 32px;
  overflow-x: clip;
}

/* Custom scrollbar */
.list::-webkit-scrollbar {
  width: 20px;
}

.list::-webkit-scrollbar-track {
  background: var(--general-general-40);
  border-radius: 10px;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--primary-primary-70);
  border-radius: 10px;
  box-shadow: inset 0 4px 16px rgba(0, 0, 0, 0.1);
}

.list::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-primary-80);
}

.project-list__archive span,
.project-list__back span {
  text-transform: uppercase;
}
@media screen and (max-width: 1920px) {
  .list {
    height: unset;
    overflow-y: unset;
    padding-right: 0;
  }
  .project-list h2,
  .users__heading h2 {
    font-size: 48px;
  }
  .project-row.opened .project-list:first-child {
    display: none;
  }
}
@media screen and (max-width: 1024.98px) {
  .project-list h2,
  .users__heading h2 {
    font-size: 40px;
  }
  .project-list {
    margin-right: 0;
  }
  .project-list__append .project-list__image,
  .header-link .project-list__image {
    width: 38px;
    height: 38px;
  }

  /* .list {
    height: 1100px;
  } */
  .project-row.opened .project-list:first-child {
    display: block;
  }
  .project-list:only-child {
    margin: 0px;
  }
  .list::-webkit-scrollbar {
    width: 15px;
  }
}
@media screen and (max-width: 768.98px) {
  .project-list__append {
    background-color: var(--primary-primary-80);
    padding: 15px;
    display: inline-block;
  }
  .project-list__title-buttons {
    column-gap: 20px;
  }
  .project-list__append .project-list__image img,
  .project-list__archive .project-list__image img,
  .project-list__back .project-list__image img {
    filter: var(--primary-primary-30-filter);
  }
  .project-list__append:hover {
    background-color: var(--general-general-70);
  }
  .project-list .project-list__archive,
  .project-list .project-list__back {
    padding: 15px;
    display: inline-block;
    background-color: var(--general-general-80);
  }
  .project-list__title {
    margin-right: 0px;
  } /* 
  .project-list__append {
    background-color: transparent;
    padding: 0;
    margin-right: 20px;
  } */
  .header-link img {
    width: 28px;
    height: 28px;
  }
  .project-list {
    padding: 12px;
  }
  .list {
    margin-right: 0px;
    /* padding-right: 10px; */
  }
  .project-list h2,
  .users__heading h2 {
    font-size: 28px;
  }
  .project-list {
    margin-right: 0;
  }
  .project-list__append .project-list__image,
  .header-link .project-list__image {
    width: 28px;
    height: 28px;
    margin-right: 0;
    display: block;
  }
  /* .list {
    height: 800px;
  } */
  .list::-webkit-scrollbar {
    width: 10px;
  }
}
