.unique-btn {
  padding: 20px 64px;
  background-color: var(--general-general-80);
  border: 0;
  text-transform: uppercase;
  color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 0px #0000001a;
  border-radius: 16px;
  display: inline-flex;
  transition: all 0.3s ease;
  cursor: pointer;
  align-items: center;
}
.unique-btn span {
  font-size: clamp(24px, 3vw, 40px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.5px;
}

.mini {
  padding: 10px;
}

.danger-btn {
  background-color: var(--system-red-1) !important;
}
.danger-btn:hover,
.danger-btn:active {
  background-color: var(--general-general-70) !important;
}
.small {
  padding: clamp(10px, 2vw, 20px);
}
.small-mn {
  padding: clamp(10px, 2vw, 20px);
}
.large {
  padding: 20px clamp(20px, 4vw, 64px);
}
.big {
  padding: 40px clamp(40px, 4vw, 64px);
}

.unique-btn.primary-blue-bg {
  background-color: var(--primary-primary-80);
}

.unique-btn.primary-blue-bg > div > img {
  filter: var(--primary-primary-30-filter);
}

.unique-btn.primary-blue > div > img {
  filter: var(--primary-primary-80-filter);
}

.unique-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  filter: var(--primary-primary-30-filter);
}

.unique-btn.primary-red-bg {
  background-color: var(--system-red-1);
}

.unique-btn.primary-red-bg:hover,
.unique-btn.primary-red-bg:active {
  background-color: var(--system-red-3);
}

.unique-btn.primary-red > div {
  filter: var(--system-red-1-filter);
}

.unique-btn.primary-red:hover > div,
.unique-btn.primary-red:active > div {
  filter: var(--system-red-3-filter);
}

.unique-btn.x-mini > div {
  width: 30px;
  height: 30px;
}

.unique-btn.x-mini > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.unique-btn:hover,
.unique-btn:active {
  background-color: var(--general-general-70);
}

.btn__image {
  width: clamp(32px, 4vw, 52px);
  height: clamp(32px, 4vw, 52px);
}
.small-mn .btn__image {
  width: 32px;
  height: 32px;
}
.btn__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.unique-btn.x-mini {
  padding: 5px;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

@media screen and (max-width: 768.98px) {
  .unique-btn:not(.bg) {
    font-size: 24px;
    background-color: transparent;
    padding: 0;
    color: transparent;
    display: inline-block;
    box-shadow: none;
  }

  .bg {
    font-size: 24px;
    display: inline-block;
    box-shadow: none;
  }

  .bg.small {
    /* padding: 10px; */
  }

  .unique-btn *:not(div, img) {
    display: none;
  }

  .unique-btn:not(.bg) img {
    filter: var(--primary-primary-80-filter);
  }

  .unique-btn:not(.bg):hover,
  .unique-btn:not(.bg):active {
    background-color: transparent;
  }
}
