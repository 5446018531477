.dragArea {
  width: 100%;
  height: 200px;
  border: 1px dashed gray;
  background-color: transparent;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: border-color 0.3s, background-color 0.3s;
  gap: 36px;
}

.dragOver {
  border-color: var(--primary-primary-100);
  background-color: var(--primary-primary-50);
}

@media screen and (max-width: 1024.98px) {
  .dragArea {
    /* max-width: 200px; */
    flex-direction: column;
    gap: 0px;
    /* padding-bottom: 22px; */
  }
}

@media screen and (max-width: 480.98px) {
  .dragArea {
    /* max-width: 100%; */
    height: 142px;
    flex-direction: row;
  }
}
