/* .users {
  min-height: 100%;
}

.users {
  width: 80%;
  margin: 0 auto;
}

.users .profile {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid black;
}
.users .profile:last-child {
  border-bottom: 0;
}

.users .avatar {
  width: 150px;
  height: 150px;
  align-self: center;
  margin-right: 20px;
  object-fit: cover;
}

.users .label {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.users .info {
  font-size: 18px;
}

.users .info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.users .list {
  min-height: 100%;
}

.users .profile-link {
  color: rgb(0, 0, 238);
}
 */

.users {
  padding: 120px 0px 0px;
}
.users__row, .profile__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 64px;
}

.users__container {
}
.users__column {
  grid-column: span 2;
  /* height: 100%; */
  /* width: 100%; */
  text-align: center;
  padding: 32px;
  background-color: var(--primary-primary-30);

  box-shadow: 0px 4px 16px 6px #0000001a;
  border-radius: 32px;
}

.project-users__row.opened .users__column {
  height: auto;
}
.users__heading {
  margin: 0px 52px 32px 0px;
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
}
.users__heading .users__title {
  text-align-last: left;
  flex: 1 1 auto;
  margin-right: 20px;
}
.users__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actions-users {
  flex: 1 1 auto;
}
.actions-users__search {
}
.actions__search {
  position: relative;
  margin-right: 32px;

  width: 100%;
  max-width: 680px;
  min-width: 200px;
  flex: 1 0 50%;
}
.actions-users__search-input {
  border: 1px solid var(--primary-primary-100);
  border-radius: 16px;
  height: 96px;
  width: 100%;
  max-width: 680px;
  min-width: 200px;
  padding: 24px 24px 24px 76px;
  background-color: var(--primary-primary-30);
}
.actions-users__search-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.actions-users__search-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.button-append {
}
.button-append__image {
  width: 54px;
  height: 54px;
}
.users__list {
  height: 1473px;
  overflow-y: scroll;
  border-radius: 10px;
  padding-right: 32px;
}

/* Custom scrollbar */
.users__list::-webkit-scrollbar {
  width: 20px;
}

.users__list::-webkit-scrollbar-track {
  background: var(--general-general-40);
  border-radius: 10px;
}

.users__list::-webkit-scrollbar-thumb {
  background-color: var(--primary-primary-70);
  border-radius: 10px;
  box-shadow: inset 0 4px 16px rgba(0, 0, 0, 0.1);
}

.users__list::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-primary-80);
}
.list-users {
}
.placeholder-action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  grid-column: span 1;
  text-align: center;
  padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 6px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.placeholder-project-list__image {
  width: 100%;
  max-width: 1024px;
  height: auto;
  aspect-ratio: 1;
}
.placeholder-action h4 {
  color: var(--general-general-100);
}
.placeholder-action h5 {
  color: var(--general-general-80);
}
@media screen and (max-width: 1920px) {
  .users {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 70px 10px;
  }
  .placeholder-action {
    display: none;
  }
  .users__row, .profile__row {
    grid-template-columns: 1fr;
  }

  .users__row.opened .users__column:first-child  {
    display: none;
  }
}
.users__column.only-child {
  margin-right: 0;
}

@media screen and (max-width: 1200px) {
  .users__heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .users__actions {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .users__list::-webkit-scrollbar {
    width: 15px;
  }
  .users {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 70px 10px;
  }
}

@media screen and (max-width: 768px) {  
  .users__column {
    padding: 16px;
  }
  .users__list {
    padding-right: 16px;
  }
  .users__list::-webkit-scrollbar {
    width: 10px;
  }
  .actions-users__search-input {
    height: 60px;
  }
}
