.departments-edit-page {
  width: 80%;
  margin: 0 auto;
}

.departments-edit-page .name {
  font-size: 20px;
  font-weight: bold;
}

.departments-edit-page .form-actions {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-top: 60px;
  margin-bottom: 40px;
  /* justify-content: center; */
  min-width: 320px;
  max-width: 966px;
}

.department-list-item .private-department-name {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-color);  
}

/* .departments-edit-page .department-list-container {
  display: flex;
  flex-direction: row;
} */

@media (max-width: 1120px) {  
  .departments-edit-page .form-actions {
    align-items: center;
    flex-direction: column;
    /* max-width: 430px;     */
  }
  .departments-edit-page .form-actions .btn {
    width: auto;
  }
}
