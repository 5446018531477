.huddle-boards-container {
  display: none;
}
.huddle-boards-container.huddle-boards-container-active {
  display: block !important;
  transition: display 2s ease-in-out;
}

.huddle-boards-left-container,
.huddle-boards-right-container {
  z-index: 3;
  position: fixed;
  bottom: 0;
  width: 96px;
  padding-top: calc(var(--nav-bar-height) + 108px);
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 108px;
  /* padding: calc(var(--nav-bar-height) + 108px) 20px 168px 20px; */
  /* height: calc(100% - 336px); */
  height: calc(100vh - var(--nav-bar-height) - 216px);
  background-color: #ecf2ff;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.huddle-boards-left-container {
  left: 0;
  justify-content: space-between;
}

.huddle-boards-right-container {
  right: 0;
  justify-content: space-between;
}

.huddle-boards-center-container {
  z-index: 3;
  position: fixed;
}

.huddle-boards-left-container .huddle-board,
.huddle-boards-right-container .huddle-board,
.huddle-board-center-left-links .huddle-board {
  background-color: #d9e4ff;
  color: #7e84a3;
  border-radius: 8px;
  width: 96px;
  height: 96px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.huddle-boards-right-container .huddle-board-column,
.huddle-boards-left-container .huddle-board-column {
  width: 50%;
  gap: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.huddle-board:hover {
  background-color: #cddafa;
  cursor: pointer;
}

.huddle-board-center-left, .huddle-board-center-right {
  display: none;
  background-color: var(--huddle-board-bg-color);
  position: fixed;
  overflow-x: visible;
  overflow-y: scroll;
  top: calc(var(--nav-bar-height) + 108px);
  width: var(--huddle-board-center-width);    
  height: var(--huddle-board-center-height);    
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
}

.huddle-board-center-left-links {
  display: none;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
}

.huddle-board-center-left {
  left: 136px;
  border-top-right-radius: 64px;
  border-bottom-right-radius: 64px;
}

.huddle-board-center-right {
  right: 136px;
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
}

.huddle-board-active {
  background-color: var(--brand-color) !important;
  box-shadow: none !important;
  color: var(--huddle-board-bg-color) !important;
}

.huddle-board-active img {
  filter: brightness(10);
}
.huddle-board-center-active {
  display: flex !important;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  -webkit-user-select: none;
  user-select: none;
}

.huddle-boards-background-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.huddle-boards-group-title {
  display: none;
}

/* START SQCPD Board */
.SQCPD-board {
  font-family: 'VCR-OSD-MONO';
  font-size: 53px !important;
  font-weight: normal !important;
}
/* END SQCPD Board */

/* START Huddle Board Project View Panels*/
.huddle-board .huddle-board-project-view-icon,
.huddle-board-center-left-links .huddle-board-project-view-icon {
  font-size: 53px !important;
}

.huddle-board-project-view-icon-tag {
  position: relative;  
  width: 24px;
  height: 24px;
  margin-top: -24px;
  top: -66px;
  left: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;  
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
/* END Huddle Board Project View Panels*/



/*========================================================================================================================================================*/

.back-button {
  background-color: var(--main-color);
  color: #fff;
  font-size: 52px;
  cursor: pointer;
  width: 96px;
  height: 96px;
  position: absolute;  
  z-index: 1;
  border: none;
  outline: none;
  border-radius: 0px 10px 10px 0px;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  padding-bottom: 10px;
  border: 2px solid var(--main-color);
}

.huddle-board-center-right .back-button {  
  right: unset;
  left: 0;
  top: 50%;
}

.huddle-board-center-left .back-button {  
  left: unset;
  right: 0;
  top: 45%;
  rotate: 180deg;
}


.btn-add {
  /* width: 50px; */
  margin-top: 2rem;
  color: #fff;
  background-color: var(--dark-accept-color);
  /* height: 50px; */
  /* font-size: 40px; */
  /* border-radius: 50%; */
  display: inline;
  border: none;
  cursor: pointer;
}

/* .btn-huddle-board {

}

.btn-huddle-board:hover {
  
} */

.btn-huddle-board-tooltip-left, .btn-huddle-board-tooltip-right, .btn-project-nav-right-button-tooltip, .btn-account-page-tooltip {
  opacity: 0;
  /* display:inline-block; */
  position: absolute;
  z-index: 1;
  width: 120px;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: medium;  
  margin-top: 3px;  
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.btn-huddle-board-tooltip-left {  
  margin-left: 252px;  
}

.btn-huddle-board-tooltip-right {  
  margin-right: 252px;
  font-family: var(--default-font);
  font-weight: bold;
}
.btn-project-nav-right-button-tooltip, .btn-account-page-tooltip {
  margin-top: 192px;
  text-align: center;
}

.btn-huddle-board:not(.huddle-board-active):hover .btn-huddle-board-tooltip-left,
.btn-huddle-board:not(.huddle-board-active):hover .btn-huddle-board-tooltip-right,
.project-nav-right-button:hover .btn-project-nav-right-button-tooltip,
.button-view:hover .btn-account-page-tooltip {
  opacity: 1;
  transition: opacity 0.5s ease-in-outs;
}

.back-button:hover {
  background-color: #fff;
  border: 2px solid var(--main-color);
  color: var(--main-color);
}
.huddle-board-content {
  /* padding: 120px; */
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  /* margin-top: 120px; */
  padding-left: 120px;
  padding-right: 120px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;  
}
/* .huddle-board-content__golden-moments {
  padding-left: 120px;
  padding-right: 120px;
}

.huddle-board-content__daily-agenda {
  padding-left: 120px;
  padding-right: 120px;
}

.huddle-board-content__attendance {
  padding-left: 120px;
  padding-right: 120px;
}

.huddle-board-content__weekly-priorities {
  padding-left: 120px;
  padding-right: 120px;
} */

.huddle-board-content-title {
  font-size: 96px;
  font-weight: 400;
  line-height: 115px;
}
.huddle-board-content-description {
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
}
.huddle-board-content-description strong {
  font-weight: 600;
}

.huddle-board-tag-filter {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  margin-bottom: 32px;
}

.huddle-board-tag-filter-item {
  cursor: pointer;

}

/* @media screen and (max-width: 1080px) {
  .huddle-board-content {
    padding: 80px;
  }
} */

.row-indicator {
  width: 120px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #111;
  /* background-color: #fff; */
  border: 1px solid #111;
  cursor: default !important;
}

.row-indicator.indicator {
  background-color: #D7DBEC;
}

.indicator {
  width: 120px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #111;
  /* background-color: var(--light-color); */
  background-color: #D7DBEC;
  border: 1px solid #111;
  font-size: 52px;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.huddle-board-content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
}

.huddle-board-content__container {
  margin: 20px 20px 20px 0px;
  /* flex: 1 1 50%; */
  width: calc(var(--huddle-board-center-width) - 1272px);
}

.huddle-board-content__daily-agenda-container {
  width: calc(var(--huddle-board-center-width) - 900px);
}

.huddle-board-content__attendance-container {
  width: calc(var(--huddle-board-center-width) - 872px);
}

.huddle-board-content__sponsor-report-container {
  /* margin: 20px 50px 20px 0px;   */
  width: calc(var(--huddle-board-center-width) - 900px);
}

.huddle-board-content__team-pulse-check-container {
  width: calc(var(--huddle-board-center-width) - 1000px);
}

.transparent-indicator {
  background-color: transparent;
}

.indicator-green {
  background-color: var(--accept-color);
}

.indicator-red {
  background-color: var(--danger-color);
}

.empty {
  border: 1px solid transparent;
}

.huddle-board-content__description-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.huddle-board-content-edit {
  /* position: absolute;
  z-index: 2;
  top: 10%;
  right: 8%; */
  display: flex;
  align-items: center;
}

.huddle-board-content-edit .title-edit-cancel-icon,
.huddle-board-content-edit .title-edit-save-icon {
  width: 30px;
  margin-left: 20px;
  height: 30px;
  cursor: pointer;
}

.huddle-board-content__description.title-edit-icon {
  width: 30px;
  margin-left: 20px;
  height: 30px;
  cursor: pointer;
  opacity: 0.1;
}

.huddle-board-content__description.description {
  font-size: 40px;
  line-height: 160%;
  letter-spacing: 0px;
  font-weight: normal;
}

.huddle-board-content__title.title {
  font-size: 96px;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: normal;
  margin: 0;
}

.huddle-board-content__sub-title.title {
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: normal;
  margin: 0;
  width: calc(var(--huddle-board-center-width) - 240px);
}

.huddle-board-content__date-row.date-row {
  font-size: 32px;
  line-height: 160%;
  letter-spacing: 0.3px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.huddle-board-content__date-row.date-row .month-select-button {
  cursor: pointer;
  background-color: var(--light-color);
  opacity: 0.5;
  /* padding: 10px; */
  padding: 10px 40px;
  border-radius: 100px;
  height: fit-content;
}
.huddle-board-content__date-row.date-row .month-select-button:hover {  
  opacity: 1;
}

.huddle-board-content__date-row.date-row .month-select-current {
  padding: 10px 40px;
}

.huddle-board-content-edit .edit-form {
  /* opacity: 0; */
  /* visibility: hidden; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
}

/* .huddle-board-content-edit .form-active {
  visibility: visible;
  opacity: 1;
} */

.huddle-board-content-edit .edit-form input {
  padding: 22px 166px 22px 0px;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-right: -150px;
  font-size: 40px;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: 0px;
  width: calc(var(--huddle-board-center-width) - 400px);
  background-color: white;
  font-family: var(--default-font);
  opacity: 1;
}

.huddle-board-content-edit .edit-form input:focus {
  outline: none;
}

.huddle-board-content-edit .edit-form button {
  /* padding: 17px; */
  background-color: unset;
  /* color: #fff; */
  border: 0px;
  cursor: pointer;
  opacity: 0.1;
}

.huddle-board-content-edit .edit-form button:hover,
.huddle-board-content-edit .edit-form button:active,
.huddle-board-content__description.title-edit-icon:hover {
  opacity: 0.9;
}

/* START Huddle Board Init Form */
.huddle-board-init {
  text-align: center;
}

.huddle-board-init-form input {
  padding: 16px 16px 16px 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  font-size: 1em;
  font-weight: bold;
  /* line-height: 20px; */
  width: 2062px;
  background-color: white;
  font-family: var(--default-font);
  opacity: 1;
}

.huddle-board-init-form input:focus {
  outline: none;
}

.huddle-board-init-form button,
.kpi-view__form-create button {
  background-color: var(--accept-color);
  color: var(--black-color);
  font-size: 1em;
  font-weight: bold;
  padding: 16px 32px 16px 32px;
  margin-top: 48px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.huddle-board-init-form button:hover,
.kpi-view__form-create button:hover {
  opacity: 1;
}

.split-by-tag-toggle {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  margin-top: 32px;
}

.split-by-tag-toggle-button, .split-by-tag-toggle-button-active {  
  color: var(--black-color);
  font-size: 1em;
  font-weight: bold;
  padding: 16px 32px 16px 32px;
  margin-top: 48px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  opacity: 0.9;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.split-by-tag-toggle-button-active {
  background-color: var(--light-color);  
}
/* END Huddle Board Init Form */

.today {
  /* background-color: var(--highlight-color) !important; */
  box-shadow: inset 8px 8px 8px var(--highlight-color), inset -8px -8px 8px var(--highlight-color);
}

/* START Huddle Board Transmissions */
.huddle-board-content__transmissions .transmissions-filter {
  display: flex;
  flex-direction: row;
  /* gap: 32px; */
  justify-content: flex-start;
  margin-top: 32px;
}

.huddle-board-content__transmissions .transmissions-filter .transmissions-filter__item {
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 2.5px;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 36px;
  padding-right: 36px;
  font-weight: 500;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  text-align: center;
  /* margin-right: 20px; */
  border: 1px solid var(--general-general-50);
}

.huddle-board-content__transmissions .transmissions-filter .transmissions-filter__item.active {
  background-color: #ccc;
  color: white;
  border: none;
} 
/* END Huddle Board Transmissions */

.huddle-board-notification-badge {
  position: relative;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: var(--system-red-1);
  border-radius: 50%;
  margin-left: -15px;
  margin-top: -40px;
  margin-right: -5px;
}
@media (max-width: 1920px) {
  .huddle-board-button {
    display: none;
  }  

  /* START Huddle Boards Mobile */
  .huddle-boards-left-container, .huddle-boards-right-container {
    position: unset;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    padding-top: 60px;
    padding-bottom: 0px;
    justify-content: center;
  }

  .huddle-boards-left-container {
    border-top: 1px solid #B6CBFF;
  }
  .huddle-boards-right-container {
    flex-direction: column;
    gap: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #B6CBFF;
  }
  .huddle-boards-right-container .huddle-board-column,
  .huddle-boards-left-container .huddle-board-column {
    width: auto;
    padding-top: 60px;
    gap: 40px;
    display: flex;
    flex-direction: row;    
    justify-content: center;
    flex-wrap: wrap;
    border-top: 1px solid #B6CBFF;
  }

  .huddle-boards-group-title {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    line-height: 32px;
  }

  .huddle-boards-background-overlay {
    display: none;
  }

  .huddle-boards-left-container.huddle-board-is-active,
  .huddle-boards-right-container.huddle-board-is-active {
    display: none;
  }

  .huddle-boards-center-container.huddle-board-is-active {
    position: unset;
  }

  .huddle-board-center-active {
    right: unset;
    position: unset;
    border-radius: unset;
    width: auto;
    height: auto;
    flex-direction: column-reverse;
  }

  .huddle-board-center-active .huddle-board-content-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
    padding-top: 60px;
  }

  .huddle-board-content__container.container, .huddle-board-content-metric {
    width: auto;
    min-width: 343px;
    margin: 0;
  }

  .btn-huddle-board-tooltip-left,
  .btn-huddle-board-tooltip-right {  
    margin-left: unset;
    margin-right: unset;      
  }

  .row-indicator {
    width: 40px;
    height: 31px;    
  }
  
  .indicator {
    width: 40px;
    height: 31px;    
    font-size: 18px;    
  }

  .huddle-board-content__date-row.date-row {
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.3px;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  .huddle-board-content__description.title-edit-icon {
    width: 14px;
    margin-left: 10px;
    height: 14px;
    cursor: pointer;
    opacity: 0.1;
  }

  .huddle-board-content-edit .title-edit-cancel-icon, .huddle-board-content-edit .title-edit-save-icon {
    width: 14px;
    margin-left: 10px;
    height: 14px;
    cursor: pointer;
  }
  
  .huddle-board-content__description.description {
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0px;
    font-weight: normal;
  }

  .huddle-board-content-edit .edit-form input {
    display: none;
  }

  .huddle-board-content-edit .edit-form textarea {
    padding: 16px 82px 16px 16px;
    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: -76px;
    font-size: 18px;
    font-weight: normal;
    line-height: 140%;
    letter-spacing: 0px;
    width: auto;
    min-width: 343px;
    background-color: white;
    font-family: var(--default-font);
    opacity: 1;
  }
  
  .huddle-board-content-edit .edit-form textarea:focus {
    outline: none;
  }

  .huddle-board-content__title.title {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0px;
    font-weight: normal;
    margin: 0;
  }

  .huddle-board-content__sub-title.title {
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0px;
    font-weight: normal;
    margin: 0;
  }

  .huddle-board-content {
    padding: 16px;
  }

  .back-button {
    position: unset;
    width: auto;
    height: auto;
    transform: rotate(-90deg);
    margin-bottom: -4px;
    margin-top: 16px;    
  }
  /* .huddle-board-content__title.title,  */
  /* .huddle-board-content__description-row,  */
  /* .huddle-board-content__date-row.date-row,   */
  /* .back-button {
    display: none !important;
  } */


  /* END Huddle Boards Mobile */
  
}