.register {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: #007FFF;
}

.register .container {
  max-width: 400px;
  flex: 1;
  min-height: unset;
  color: white;
}

.register .container a {
  color:aliceblue
}

.register .container .register-login-text, .register .container .register-text {
  text-align: center;
}

.register .container input {  
  background-color: white;
}

.register .container .tagline {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 200;
  font-size: 20px;
}

.register .container .logo {
  margin: 0;  
}

.register .fb {
  background-color: #3b5998;
  color: white;
  display: block;
  text-align: center;
}

.register .google {
  background-color: #dd4b39;
  color: white;
  display: block;
  text-align: center;
}

.register .register-text {
  /* display: inline-block; */
  /* min-width: 250px; */
  /* font-size: 20px; */
  text-align: center;
  margin: 15px;
}

.register .logins {
  margin: 5px 0;
}

.logo-register {
 height: 100px; 
}

.logo-register-container {
  text-align: center;
}
