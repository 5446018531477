.huddle-board-content__patient-centricity {
}
.patient-centricity {
  width: 100%;
  height: 100%;
}
.huddle-board-content__title {
}
.title {
}
.patient-centricity__content {
}
.patient-centricity__heading {
  margin-top: clamp(24px, 2.5vw, 32px);
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.patient-centricity__heading-title {
}
.patient-centricity__body {
  width: 100%;
  height: 100%;
}
.avatar-section {
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; */
  margin-bottom: 10px;
}
.patient-centricity__attached-image-container {
}
.patient-centricity__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.patient-centricity__edit {
}
.patient-centricity__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 80px;
  height: 80px;
  transition: filter 0.3s ease;
}
.patient-centricity__button.button-edit:hover img,
.patient-centricity__button.button-edit:active img {
  filter: var(--primary-primary-80-filter);
}
.patient-centricity__button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.patient-centricity__button.button-delete {
  background-color: transparent;
}
.patient-centricity__button.button-delete img {
  filter: var(--system-red-1-filter);
}
.patient-centricity__button.button-delete:hover img,
.patient-centricity__button.button-delete:active img {
  filter: var(--system-red-3-filter);
}
.patient-centricity__attached-image {
  width: 100%;
  /* height: 100%; */
  height: 1120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}
@media (max-width: 1980px) {
  .patient-centricity__attached-image {
    height: 575px;
  }
  .patient-centricity__button {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 810.98px) {
  .patient-centricity__attached-image {
    height: 435px;
  }
  .patient-centricity__button {
    width: 40px;
    height: 40px;
  }
}
.patient-centricity__image {
  /* max-width: 100%;
  max-height: calc(100vh - 300px);
  width: auto;
  height: auto; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.current-avatar {
}
.invisible {
}
.Material-KitSB2 {
}
.error {
}
.patient-centricity .drag-and-drop-image {
  width: 100px;
  height: 100px;
  padding: 43px;
}
.loading {
}
.loading-spinner {
}
.text-primary {
}
.patient-centricity .drag-and-drop-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.patient-centricity .drag-and-drop-text {
  color: #000;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
}
.patient-centricity .or-text {
  color: var(--general-general-70);
  text-align: center;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
}
.patient-centricity .select-file-link {
  color: var(--primary-primary-70);
  text-align: center;
  cursor: pointer;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
}
