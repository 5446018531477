.dashboard-page {
  padding: 120px 0px 0px;
  /* background-color: var(--general-general-30); */
}
.dashboard-page .project-row {
  width: 100%;
  height: 100%; /* 
  display: flex;
  justify-content: center;
  align-items: flex-start; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 64px;
}
.dashboard-page .project-row.opened {
  /* grid-template-columns: 70% 30%; */
}
.project-list__placeholder {
  /* flex: 0 1 50%;
   */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  text-align: center;
  padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 6px #0000001a;
  border-radius: 32px;
}

.project-list__placeholder * {
  margin: 0;
}
.project-list__placeholder h4 {
  color: var(--general-general-100);
}
.project-list__placeholder h5 {
  color: var(--general-general-80);
}
.placeholder-project-list__image {
  width: 100%;
  max-width: 1024px;
  height: auto;
  aspect-ratio: 1;
}

.placeholder-project-list__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1920px) {
  .dashboard-page {
    padding: 120px 40px;
  }
  .placeholder-project-list {
    display: none;
  }

  .dashboard-page .project-row {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1920.98px) {
  .dashboard-page {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 70px 10px;
  }
}
@media screen and (max-width: 1024.98px) {
  /* .dashboard-page {
    padding: 100px 10px;
  } */
  .dashboard-page .project-row {
    display: block;
  }
}
@media screen and (max-width: 768.98px) {
  .dashboard-page {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 40px 10px;
  }
}


.tags-list-container .tag-list,
.podmasters-list-container .podmaster-list,
.departments-list-container .department-list,
.project-departments-list-container .department-list {
  flex: 1 1 50%;
  border-radius: 5px;
  padding: 12px 12px;
  /* margin-top: 20px; */
  width: 100%;
}


.list.list-sm {
  height: 100%;
  max-height: 300px;
}
.tags-list-container .list .tag .tag-list-item,
.podmasters-list-container .list .podmaster .podmaster-list-item,
.departments-list-container .list .department .department-list-item,
.project-departments-list-container .list .department .department-list-item {
  flex: 1 1 auto;
}
.tags-list-container .list .tag .tag-list-item .tag-tags,
.podmasters-list-container .list .podmaster .podmaster-list-item .podmaster-podmasters,
.departments-list-container .list .department .department-list-item .department-departments,
.project-departments-list-container .list .department .department-list-item .department-departments {
  margin-left: 20px;
}
.tags-list-container .list .tag,
.podmasters-list-container .list .podmaster,
.departments-list-container .list .department,
.project-departments-list-container .list .department {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tags-list-container .tag-list .tag,
.podmasters-list-container .podmaster-list .podmaster,
.departments-list-container .department-list .department,
.project-departments-list-container .department-list .department {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid black;
}


.tags-list-container .tag-list .tag:last-child,
.podmasters-list-container .podmaster-list .podmaster:last-child,
.departments-list-container .department-list .department:last-child,
.project-departments-list-container .department-list .department:last-child {
  border-bottom: 0;
}


.tags-list-container .tag-list .tag-list-item,
.podmasters-list-container .podmaster-list .podmaster-list-item,
.departments-list-container .department-list .department-list-item,
.project-departments-list-container .department-list .department-list-item {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tags-list-container .tag-list .tag-row,
.podmasters-list-container .podmaster-list .podmaster-row,
.departments-list-container .department-list .department-row,
.project-departments-list-container .department-list .department-row {
  display: flex;
  align-items: center;
  gap: 12px;
}


.tags-list-container .tag-list .tag-actions,
.podmasters-list-container .podmaster-list .podmaster-actions,
.departments-list-container .department-list .department-actions,
.project-departments-list-container .department-list .department-actions {
  gap: 8px;
}


.tags-list-container .tag-list .tag-actions__image,
.podmasters-list-container .podmaster-list .podmaster-actions__image,
.departments-list-container .department-list .department-actions__image,
.project-departments-list-container .department-list .department-actions__image {
  /* width: 16px;
  height: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
}




.tags-list-container .tag-list .tag-actions__image img,
.podmasters-list-container .podmaster-list .podmaster-actions__image img,
.departments-list-container .department-list .department-actions__image img,
.project-departments-list-container .department-list .department-actions__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .departments-list-container .department-list .button-move-down .department-actions__image img,
.departments-list-container .department-list .button-move-up .department-actions__image img, */
.departments-list-container .department-list .button-move-down .department-actions__image,
.departments-list-container .department-list .button-move-up .department-actions__image,
.project-departments-list-container .department-list .button-move-down .department-actions__image,
.project-departments-list-container .department-list .button-move-up .department-actions__image {
  width: 40px;
  height: 40px;
}



@media (max-width: 1120px) {
  .tags-list-container,
  .podmasters-list-container,
  .departments-list-container,
  .project-departments-list-container {
    flex-direction: column;
    align-items: center;
  }

}
@media (max-width: 780px) {
  .tags-list-container .list .tag,
  .podmasters-list-container .list .podmaster {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
