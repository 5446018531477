.tag-edit-page .dashboard-form {
}
.tag-edit-page .project-list {
}
.tag-edit-page .error-center {
}
.tag-edit-page .form-container {
}
.tag-edit-page .project-form__heading {
}
.tag-edit-page .action-btn {
}
.tag-edit-page .bg {
}
.tag-edit-page .primary-blue-bg {
}
.tag-edit-page .btn__image {
}
.tag-edit-page .circle-picker {
  margin-bottom: 40px !important;
}
.tag-edit-page .list {
  margin-top: 20px;
}
.tag-edit-page .time {
}
.tag-edit-page .text-light {
}
.tag-edit-page .fullname {
}
.tag-edit-page .label {
}
.tag-edit-page .form-actions {
}
.tag-edit-page .project__buttons {
}
.tag-edit-page .btn {
}
.tag-edit-page .submit-btn {
}
.tag-edit-page .save-btn {
  background-color: var(--system-green-1);
  margin-right: 32px;
}
.tag-edit-page .cancel-btn {
  background-color: var(--system-red-1);
}
