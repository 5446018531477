/* .department-list .list {
  margin: 17px 0;
  margin-right: 40px;
  padding: 12px 12px;
  border-radius: 5px;
  background-color: #d9d9d9;
  min-width: 430px;
  max-width: 778px;
}

.department-list .department-btn {
  background-color: white !important;
  color: #007bff !important;
}

.department-list .list .department {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.department-list .list .department-name {
  font-size: 20px;
  font-weight: bold;
  min-width: 150px;
}

@media (max-width: 1120px) {
  .department-list .list {
    margin-right: 0;
  }
}

@media (max-width: 780px) {
  .department-list .list .department {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} */
.department-list-container .department-list {
  flex: 1 1 50%;
  border-radius: 5px;
  padding: 12px 12px;
}
.department-list-container.list.list-sm {
  height: 100%;
  max-height: 300px;
}
.department-list-container.list .department .department-list-item {
  flex: 1 1 auto;
}
.department-list-container.list .department .department-list-item .department-tags {
  margin-left: 20px;
}
.department-list-container.list .department {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 780px) {
  .department-list-container.list .department {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
