.department-form {
  margin-top: 17px;
  margin-bottom: 40px;
  margin-right: 40px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  min-width: 430px;
  max-width: 760px;
  height: 530px
}

.department-form .btn {
  width: auto;
}

.department-form .label {
  width: auto;
  margin: 0;
}

.department-form .department-form-select-field {
  height: 44px;
  width: 148px;
  margin: 5px 0;
  padding: 12px 0;
  border-radius: 4px;
  
}

.department-form .department-form-color-field {
  margin: 5px 0px;
  height: 110px;
  padding: 12px;  
}

.department-form p {
  margin: 0;
}

@media (max-width: 1120px) {
  .department-form {
    margin-right: 0;
  }
}