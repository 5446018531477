.department-edit-page {
  width: 80%;
  margin: 0 auto;
}

.department-edit-page p {
  margin: 0;
}

.department-edit-page .name {
  font-size: 20px;
  font-weight: bold;
}

.department-edit-page .label {
  width: auto;
  margin: 10px 0;
}

.department-edit-page .form-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.department-edit-page .form-actions .btn {
  margin: 0 10px;
}

.department-edit-page .form-container {
  min-width: 320px;
  max-width: 780px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
  background-color: #f5f5f5;
  padding: 0 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.department-edit-page .user-list-container {
  margin: 17px 0;
  padding: 12px 12px;
  border-radius: 5px;
  background-color: #d9d9d9;
}

.department-edit-page .user-list-container:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.department-edit-page .user-list-container .user-list-item {
  border-bottom: 1px solid black;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.department-edit-page .user-list-container .user-list-item:last-child {
  border-bottom: none;
}

.department-edit-page .user-list-container .user-list-item .user-name {
  font-weight: bold;
  min-width: 200px;
}

/* .user-info-container {
  display: flex;
  flex-direction: column;
} */

.user-list-link {
  text-decoration: none;
  color: black !important;
}

.user-info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-width: 100px;
  margin-right: 24px;
  padding-top: 2px;
  padding-bottom: 2px;
}
/* Color Picker Container */
.circle-picker {
  margin: 16px 0 !important;
}

.memeber-list-item {
  display: flex;
  align-items: center;
}
.member-row {
  flex: 1 1 auto;
}

/* Individual Circle Swatch */
.circle-swatch {
  width: 80px !important;
  height: 80px !important;
  margin: 4px !important;
}

/* Circle Container */
.circle-picker > span {
  width: 80px !important;
  height: 80px !important;
}

/* Circle itself */
.circle-picker > span > div {
  width: 80px !important;
  height: 80px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

/* Circle background */
.circle-picker > span > div > div {
  background: inherit !important;
  box-shadow: none !important;
}

/* Layout container */
.circle-picker > div {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, 80px) !important;
  gap: 8px !important;
  width: 100% !important;
}

/* Alternative approach using more specific selectors */
[class*='circle-picker'] {
  width: 100% !important;
}

[class*='circle-picker'] > div {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, 80px) !important;
  gap: 8px !important;
}

[class*='circle-picker'] span {
  width: 80px !important;
  height: 80px !important;
  margin: 2px !important;
}

[class*='circle-picker'] span > div {
  width: 80px !important;
  height: 80px !important; /* 
  border-radius: 0 !important; */
}

@media (max-width: 720px) {
  .department-edit-page .user-list-container .user-list-item {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
}

/*.department-edit-page .users-list {
   margin: 10px 0;
  padding: 10px;
  background-color: #daebfc;
  border-radius: 5px; 
  
}*/

/* .users-list-item:last-of-type {
  border-bottom: none;
  margin-bottom: 20px;
} */

/* .users-list-item {
  border-bottom: 1px solid black;
  padding: 10px 0
} */

/* .user-name {
  font-style: italic;
} */
