.item-kpi-view__table-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.item-kpi-view__label {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 1.8px;
}
.green-label {
  color: var(--system-red-1);
}
.blue-label {
  color: var(--primary-primary-70);
}
.item-kpi-view__table {
  /* 
  width: 100%; */
  border-collapse: collapse;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
}

.item-kpi-view__table tr.kpi-view__metric-table-header {
  border-bottom: 1px solid black;  
  border-collapse: collapse;
}

.item-kpi-view__table tr.kpi-view__metric-table-row {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.item-kpi-view__table tr.kpi-view__metric-table-row-actual {
  background-color: var(--primary-primary-30);
}

.kpi-view__metric-table-row-input {
  padding: 0;
  margin: 0;
}

.item-kpi-view__table th {
  border-collapse: collapse;
}

.item-kpi-view__table td,
.item-kpi-view__table th {
  text-align: center;
  line-height: clamp(25px, 1.5vw, 45px);
  font-size: clamp(16px, 1.5vw, 24px);
  flex: 1;
  min-width: 0;
}
.item-kpi-view__table th {
  padding: 10px 20px;
}
.item-kpi-view__table td {
  overflow: hidden;
}
/* .item-kpi-view__table td:first-child {
  text-align: center;
  padding: 10px 20px;
} */
.item-kpi-view__table td input {
  text-align: center;
  /* padding: 10px 20px; */
  background-color: transparent;
  /* opacity: 0.7; */
  /* line-height: clamp(25px, 1.5vw, 45px); */
  /* font-size: clamp(16px, 1.5vw, 24px); */
  width: 100%;
  height: 100%;
}
.item-kpi-view__table td input:focus {
  outline: none;
  opacity: 1;
}
.item-kpi-view__table tr {
  border-bottom: 1px solid #e0e0e0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.item-kpi-view__table-metric-today {
  background-color: var(--general-general-60);
  border-radius: 32px;
  padding: 0px 15px !important;
  margin: 10px 5px;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
