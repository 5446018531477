.container { 
  
}
@media (min-width: 1921px) {
  .container {
    margin-top: var(--nav-bar-height);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}
@media (max-width: 1920px) {
  .container {
    margin: 0 auto;
  }
}
