/* Base modal structure */
.modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  border-radius: 16px;
  padding: 32px;
  background-color: white;
  box-shadow: 10px 10px 40px #00000069;
}
.modal button.form-actions-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
/* Common modal header */
.modal-title-row {
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-bottom: clamp(30px, 20px, 10px);
}

.modal-title-close {
  padding: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-title-close__svg {
  width: 40px;
  height: 40px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(13%) saturate(343%) hue-rotate(191deg)
    brightness(87%) contrast(87%);
  transition: filter 0.3s ease;
}

.modal-title-close:hover .modal-title-close__svg {
  filter: brightness(0) saturate(100%) invert(31%) sepia(9%) saturate(1407%) hue-rotate(190deg)
    brightness(89%) contrast(88%);
}

/* Common form elements */
.modal .error {
  background-color: #f8d7da;
  color: #df4759;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}

.modal textarea {
  resize: none;
}

/* Media queries */
@media (max-width: 1920px) {
  .modal {
    padding: 16px;
  }
}
.modal .modal-title {
  font-size: 40px;
  line-height: 140%;
  font-weight: 500;
  letter-spacing: 0; /* 
    margin: 0px 0px 16px 0px; */
  color: rgba(51, 55, 82, 1);
}
@media (max-width: 1920px) {
  .modal .modal-title {
    font-size: 27px;
  }
}

/* .form-department-selected {
  color: var(--general-general-100);
}
.form-department-selected.editing {
  opacity: 0;
} */
/* .form-department-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

.form-department-row {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}
 */
/* .form-department-row-content {
  max-height: 71.1px;
  overflow-y: auto;
  padding: 12px 16px;
  border-radius: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none; 
} */

/* Custom minimal scrollbar for Webkit browsers */
/* .form-department-row-content::-webkit-scrollbar {
  width: 2px;
}

.form-department-row-content::-webkit-scrollbar-track {
  background: transparent;
}

.form-department-row-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1px;
} */

/* Hide default scrollbar buttons */
/* .form-department-row-content::-webkit-scrollbar-button {
  display: none;
} */

.form-department {
  /* display: flex;
  padding: 12px 32px;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  color: var(--general-general-100);
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  transition: box-shadow 0.3s ease-in-out; */
}

/* .remove-department-icon::after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-color: var(--general-general-90);
}

.remove-department-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(99%) sepia(6%) saturate(22%) hue-rotate(229deg)
    brightness(105%) contrast(100%);
} */

.form-actions-edit {
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--general-general-60);
  background-color: transparent;
  transition: all 0.3s ease;
}

.form-actions-edit.editing {
  background-color: var(--general-general-60);
}

.form-actions-edit:hover {
  background-color: var(--general-general-60);
}
/* 
.form-department-list {
  background: white;
  border: 1px solid var(--brand-color);
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  padding: 24px;
  width: 480px;
  max-height: 320px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form-department-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
} */
/* .form-department-row,
.form-due-date-container {
  position: relative;
} */
/* .form-due-date {
  background-color: var(--primary-primary-40);
}
.form-due-date-list {
  position: absolute;
  bottom: -10px;
  left: 100px;
} */
/* .form-due-date-container-inner {
  max-height: 68px;
} */
/* .form-department-row.editing {
} */
/* 
.form-due-date-selected.editing {
  opacity: 0;
} */
/* .form-department-list {
  display: flex;
  position: absolute;

  top: 0;
  left: 100px;
  min-width: 480px;
  width: calc(100% - 150px);
  flex-direction: column;
  gap: 20px;
  display: flex;
  padding: 24px 24px;
  border-radius: 16px;
  border: 1px solid var(--brand-color);
  background-color: #f5f6fa;
  max-height: 130px;
  overflow: auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
} */
/* .form-actions-edit {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.form-actions-edit img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1920px) {
  /* .form-department {
    padding: 6px 16px;
    font-size: 14px;
  }

  .form-department-list {
    width: 240px;
    padding: 12px;
    max-height: 160px;
    gap: 10px;
  } */
  .form-actions-edit {
    width: 27px;
    height: 27px;
  }
}
.modal .form-department-list {
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e9f4;
    border-radius: 16px;
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #608dff;
    border-radius: 16px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4f81ff;
  }
}
