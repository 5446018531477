/* Status Card Container */
.status-card {
  background-color: var(--gray-color);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-card-textarea {
  width: 100%;
  height: 100%;
  /* border: 1px solid transparent;
  transition: border 0.3s ease;
   */
  resize: none;
  background-color: transparent;
}
.status-card-textarea:not(:disabled) {
  background-color: #fff;
}
.status-card__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status-card__button {
  background-color: var(--gray-color);
  border: none;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: filter 0.3s ease;
}
.status-card__button.button-cancel img {
  filter: var(--system-red-1-filter);
}
.status-card__button.button-cancel:hover img,
.status-card__button.button-cancel:active img {
  filter: var(--system-red-3-filter);
}

.status-card__button.button-submit img {
  filter: var(--system-green-1-filter);
}
.status-card__button.button-submit:hover img,
.status-card__button.button-submit:active img {
  filter: var(--system-green-3-filter);
}
.status-card__button.button-edit:hover img,
.status-card__button.button-edit:active img {
  filter: var(--primary-primary-80-filter);
}
.status-card__button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.status-card__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Header Section */
.status-card-header {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: #333752;
}

/* Body Text */
.status-card-body p {
  font-size: 24px;
  line-height: 160%;
  font-weight: normal;
  letter-spacing: 0.8px;
  color: #333752;
  margin: 10px 0;
}

/* Buttons Container */
.status-card-buttons {
  display: flex;
  gap: 10px;
}

/* Button Styles */
.status-button {
  flex: 0 1 30%;
  padding: 8px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 2.5px;
  color: black;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* Green Button */
.green-button {
  background-color: #5bf98a;
}

.green-button:hover {
  background-color: #3cec71;
}

/* Red Button */
.red-button {
  background-color: #fc9797;
}

.red-button:hover {
  background-color: #fb8383;
}

/* Neutral Button */
.neutral-button {
  background-color: var(--light-color);
}

.neutral-button:hover {
  background-color: var(--highlight-color);
}

@media (max-width: 1920px) {
  /* START Status Card Mobile */
  .status-card-header {
    font-size: 20px;
    flex-direction: column;
  }

  .status-card-body p {
    font-size: 16px;
  }

  .status-card-buttons {
    justify-content: center;
  }

  .status-button {
    padding: 8px 24px;
    font-size: 18px;
  }
  /* END Status Card Mobile */
}
