.task-box {
  background-color: #f0f0f0;
  height: auto;
  min-height: 100px;
  line-height: 22px;
  margin: 5px 5px 10px 5px;
  /* border: 1px solid black; */
  /* border-radius: 5px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 5px 5px 7px rgba(33, 33, 33, .7); */
  min-width: 100px;
}

.project-view-page-body-row-task-cell div.task-box {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  /* transition: box-shadow 0.3s ease-in-out; */
}

.project-view-page-body-row-task-cell div.task-box:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

.task-info .task-info-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.task-info .task-info-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.task-info .task-info-top-left div {
  margin-right: 7px;
}

.task-info .task-assignedTo-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000000;
}

.task-info .task-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-title-large {
  font-family: 'Reenie-Beanie';
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  text-align: left;
  margin-bottom: 5px;
  font-size: 37px;
  line-height: 37px;
  font-weight: bold;
  overflow-wrap: anywhere;
}

.task-title-small {
  font-family: 'Reenie-Beanie';
  display: none;
  flex-wrap: wrap;
  align-content: flex-start;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  overflow-wrap: anywhere;
}

.task-delay {
  display: flex;
  flex-direction: row;
  color: red;
  font-size: 14px;
}

.task-delay-icon {
  margin-right: 5px;
  margin-top: 2px;
}

.task-delay-bar {
  width: 8px;
  height: 18px;
  background-color: red;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 4px;
}

.task-assignedTo {
  font-size: 14px;
  color: #666;
  display: flex;
  align-content: flex-start;
}

.task-assignedTo-icon {
  margin-right: 5px;
  margin-top: 2px;
}

.task-due-date {
  font-size: 14px;
  color: rgba(51, 55, 82, 1);
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.task-description {
  /* font-size: 14px; */
  color: rgba(51, 55, 82, 1);
  display: flex;
  align-content: flex-start;
  cursor: pointer;
  margin-top: 2px;
}

.task-description-noedit {
  font-size: 14px;
  color: #666;
  display: flex;
  align-content: flex-start;
  margin-top: 2px;
  /* cursor: pointer; */
}

.task p {
  margin: 0;
}

.task .task-box-btn {
  width: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.task-done-button,
.task-approve-button,
.task-decline-button {
  color: rgba(19, 21, 35, 1);
  border-radius: 16px;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  /* margin-left: 5px;
  margin-right: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  padding-right: 16px;
}

.task-done-button {
  background-color: rgba(91, 249, 138, 1);
  border: none;
  width: 100%;
}

.task-approve-button {
  background-color: #ffb144;
  border: none;
  margin-right: 5px;
  width: 100%;
}

.task-decline-button {
  background-color: rgba(252, 151, 151, 1);
  border: none;
  margin-left: 5px;
  width: 100%;
}

.task-done-button:hover,
.task-approve-button:hover,
.task-decline-button:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.task-done-button-icon,
.task-approve-button-icon {
  /* margin-right: 5px;   */
  font-size: 18px;
  /* color: green; */
}

.task-decline-button-icon {
  /* margin-right: 5px;   */
  font-size: 18px;
}

.task-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.task-header-row-noedit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-edit-icon {
  color: rgba(51, 55, 82, 1);
  font-size: 16px;
  cursor: pointer;
}

.task-description-icon {
  color: rgba(51, 55, 82, 1);
  font-size: 16px;
}

.task-actions-approval,
.task-actions-complete {
  background-color: rgba(236, 242, 255, 0.1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
}

.task-actions-approval .task-actions-approval-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.task-actions-approval-last-audit {
  font-size: 14px;
  line-height: 17px;
  color: rgba(19, 21, 35, 1);
  text-align: left;
}

.task-actions-approval-last-audit-action {
  text-transform: capitalize;
}

.task-form-buttons-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task button.form-actions-icon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
}

.form-actions-icon {
  width: auto;
  max-height: 40px;
  aspect-ratio: 1/1;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task .task-form-title {
  margin: 0;
}
.task-form-header {
  margin: 0 0 16px 0;
}
.form-actions-icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 4px;
}

.form-actions-icon.task-form-buttons-admin-delete img {
  filter: brightness(0) saturate(100%) invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(89%) contrast(97%);
}

.form-actions-icon.task-form-buttons-admin-delete:hover img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(9%) saturate(382%) hue-rotate(182deg)
    brightness(94%) contrast(90%);
}

.form-actions-icon.task-form-buttons-admin-archive img {
  filter: brightness(0) saturate(100%) invert(44%) sepia(97%) saturate(427%) hue-rotate(91deg)
    brightness(95%) contrast(88%);
}

.form-actions-icon.task-form-buttons-admin-archive:hover img {
  filter: brightness(0) saturate(100%) invert(58%) sepia(11%) saturate(458%) hue-rotate(67deg)
    brightness(97%) contrast(87%);
}

@media (max-width: 1920px) {
  .task-title-small {
    display: flex;
  }

  .task-title-large {
    display: none;
  }
  .task-box {
    width: 322px;
    margin-left: 16px;
    /* margin-right: 16px; */
    margin-bottom: 16px;
    margin-top: 0px;
  }
}

/* @media (max-width: 720px) {
  .task-box {    
    width: 100%;        
  }
} */
