.kpi-view-container {
  padding-top: 24px !important;
  padding-left: 60px !important;
}
.kpi-view-breadcrumbs {
}
.kpi-view__content {
}
.kpi-view-content {
}
.kpi-view__pacing {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  /* justify-content: space-between; */
}
.modifier {
  align-items: unset;
}
.kpi-view__pacing,
.sqcpd-kpi-view__letters {
  margin-bottom: 40px;
}
.kpi-view__department {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.kpi-view__department-slide {
}
/*here both the department-kpi-view and the swiper-slide have to have padding, otherwise the.department-kpi-view will not be fully visible*/
.department-kpi-view {
  padding: 32px 32px;
  height: min-content;
  /* height: auto; */
  border-radius: 16px;
  border: 2px solid var(--primary-primary-40);
  width: 369px;
  /* changing width to flex*/
  /* flex: 0 0 16.6%; */
}

.swiper-slide {
  padding: 15px 0px;

  height: auto !important;
}
.swiper-wrapper {
}
.department-kpi-view__title {
  overflow: hidden;
  color: var(--General-General-100, #131523);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 150% */
  text-align: left;
  height: 48px;
}
.department-kpi-view__form {
  flex: 1 1 auto;
}
.department-kpi-view__row {
  align-items: center;
}
.department-kpi-view__label {
  font-weight: 700;
}
.department-kpi-view__input {
  background-color: transparent;
  border: 0;
  overflow-x: scroll;
}
.department-kpi-view__input:focus {
  outline: none;
}
.department-kpi-view__edit-button {
}
.department-kpi-view__buttons {
  flex: 1 0 20%;
}

.kpi-edit-button.department-kpi-view__cancel-button > img {
  filter: var(--system-red-1-filter);
}

.kpi-edit-button.department-kpi-view__accept-button img {
  filter: var(--system-green-1-filter);
}
.kpi-edit-button.department-kpi-view__accept-button {
  width: 60px;
  height: 50px;
}
.kpi-edit-button.department-kpi-view__accept-button,
.kpi-edit-button.department-kpi-view__cancel-button {
  flex: 1 0 50%;
}

.kpi-edit-button {
  max-width: 40px;
  width: 100%;
  height: 40px;
  border: 0px;

  background-color: transparent;
}
button.kpi-edit-button {
  cursor: pointer;
  transition: filter 0.3s ease;
}
button.kpi-edit-button:hover img {
  filter: brightness(0.5);
}
.kpi-edit-button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0);
}
.department-kpi-view__action {
}
.action-kpi-view {
}
.department-kpi-view__delete-wrapper {
  position: relative;
  width: 100%;
  flex: 1 0 9%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.department-kpi-view__delete-button-text {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.department-kpi-view__delete-button-text span {
  white-space: nowrap;
  font-size: 22px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 10px;
  display: inline-block;
}
.department-kpi-view__confirmation-cancel-button {
  background-color: var(--general-general-40);
}
.department-kpi-view__confirmation-cancel-button:hover,
.department-kpi-view__confirmation-cancel-button:active {
  background-color: var(--general-general-50);
}
.department-kpi-view__confirmation-proceed-button {
  background-color: var(--system-red-1);
}
.department-kpi-view__confirmation-proceed-button:hover,
.department-kpi-view__confirmation-cancel-button:active {
  background-color: var(--system-red-2);
}
.department-kpi-view__delete-button-confirmation {
  gap: 10px;
}
.department-kpi-view__confirmation-cancel-button,
.department-kpi-view__confirmation-proceed-button {
  flex: 1 0 50%;
  border: 0;
  display: block;
  padding: 10px 20px;
  cursor: pointer; /* 
  box-shadow: 1px 1px 4px 5px rgba(0, 0, 0, 0.3); */
}

.action-kpi-view__icon {
}
.department-kpi-view__add-button {
  display: block;
  margin: 0 auto;
}
.department-kpi-view__updated-at {
}
.kpi-view__sqcpd {
}
.sqcpd-kpi-view {
}
.sqcpd-kpi-view__letters {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 100%;
  gap: 24px;
}
.sqcpd-kpi-view__letters .row-indicator,
.sqcpd-kpi-view__letters .row-indicator.empty {
  width: 30px;
  height: 20px;
  font-size: 18px;
}
.letters-kpi-view {
  height: 100%;
}
.letters-kpi-view__letter {
  flex: 1 1 auto;
  max-width: 600px; /* 
  display: flex;
  align-items: center;
  justify-content: center; */
  padding: 32px 32px;
  width: 600px;
  height: min-content;
  border-radius: 16px;
  border: 2px solid var(--primary-primary-40);
  background: var(--general-general-30);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: flex-start;
}
.select-period {
  height: 100%;
}
.letters-kpi-view__letter-header {
  margin-bottom: 32px;
}
.letters-kpi-view__spacer-divider-32 {
  height: 1px;
  width: 100%;
  background-color: var(--general-general-60);
  margin-top: 32px;
  margin-bottom: 32px;
}

.letters-kpi-view__spacer-divider-24 {
  height: 1px;
  width: 100%;
  background-color: var(--general-general-60);
  margin-top: 24px;
  margin-bottom: 24px;
}

.letters-kpi-view__letter-wrapper {
  max-width: 200px;
}
.select-period-button__prev {
  cursor: pointer;
}
.sqcpd-kpi-view__stats {
}
.stats-kpi-view {
}
.stats-kpi-view__item {
}
.item-kpi-view {
}
.item-kpi-view__title {
  text-align: left;
}

.item-kpi-view__content {
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: flex;

  overflow-x: hidden;
  flex-wrap: wrap; */
  gap: 40px;
}
/* .item-kpi-view__tables {
  flex: 0 1 calc(20% - 40px);
} */
/* .item-kpi-view__tables:only-child,
.item-kpi-view__tables:first-child:nth-last-child(2) {
  flex: 0 0 20%;
} */

.item-kpi-view__tables > div:not(:last-child) {
  margin-bottom: 20px;
}
.positive {
  color: var(--system-green-1);
}
.negative {
  color: var(--system-red-1);
}
.department-kpi-view__status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
.department-kpi-view__status-button {
  width: 32px;
  border: 0;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
}

.department-kpi-view__status-button:first-child {
  background-color: var(--general-general-30);
  border: 1px solid var(--general-general-60);
  color: var(--general-general-60);
  font-size: 22px;
  line-height: 28px;
  padding-bottom: 4px;
}
.department-kpi-view__status-button:first-child:hover {
  background-color: white;
}
.department-kpi-view__status-button:first-child:active {
  /* background-color: var(--system-green-3); */
}

.department-kpi-view__status-button:last-child {
  background-color: var(--system-red-1);
  color: var(--general-general-30);
  font-size: 22px;
  line-height: 28px;
  padding-bottom: 4px;
}
.department-kpi-view__status-button:last-child:hover {
  filter: brightness(1.2);
}
.department-kpi-view__status-button:last-child:active {
  background-color: var(--system-red-3);
}

.clicked {
  position: relative;
}
/* .clicked::before {
  content: '';
  background-image: url(/public/icons/check_small.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  filter: brightness(0) invert(1); 
} */

.item-kpi-view__add-table {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: var(--system-green-1);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}
.item-kpi-view__add-table:hover {
  background-color: var(--system-green-2);
}
.item-kpi-view__add-table:active {
  background-color: var(--system-green-3);
}
.graph-kpi-view__item-content {
  /* max-width: 700px; */
}
.graph-kpi-view__row {
  display: flex; /* 
  flex-wrap: wrap; */
  gap: 40px;
  overflow-x: hidden;
}
.graph-kpi-view__item {
  flex: 0 1 calc(20% - 40px);
}
.item-kpi-view__add-table-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.item-kpi-view__add-table-input-container input {
  width: 100%;
  padding: 22px 24px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  /* margin: 0px 0px 40px 0px; */
  display: inline-block;
  /* font-size: 28px;
  line-height: 160%; */
  font-family: var(--font-family);
  background-color: #f5f6fa;
  color: #131523;
  /* box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25); */
}

.item-kpi-view__add-table-input-container button {
  width: 300px;
  letter-spacing: unset;
}

.kpi-view__error-message {
  opacity: 0;
  display: none;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  left: 10%;
  width: 100px;
  height: 50px;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid var(--system-red-1);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  color: var(--system-red-1);
}
.kpi-view__error-message {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.kpi-view__error-message.visible {
  opacity: 1;
  transform: translateX(0);
  display: block;
}
.graph-kpi-view__date-range {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.date-range__input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-range__input label {
  font-weight: 500;
}

.date-range__input input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Modal Styles */
.reasons-modal {  
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  border-radius: 16px;
  box-shadow: 10px 10px 40px #00000069;
  z-index: 1000;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 680px;
  text-align: left;
}
.reasons-modal:focus-visible {
  outline: none;
}

/* Modal Overlay */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.reasons-modal__content {
  width: 100%;
}

.reasons-modal__form {
  margin-top: 20px;
}

.reasons-modal__input input,
.reasons-modal__input select {
  width: 100%;
  padding: 8px 24px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  margin: 0px 0px 40px 0px;
  display: inline-block;
  font-size: 28px;
  line-height: 160%;
  font-family: var(--font-family);
  background-color: #f5f6fa;
  color: #131523;
  box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25);
}

.reasons-modal__input label {
  display: block;
  margin-bottom: 16px;
  /* font-weight: 500; */
}

.reasons-modal__input select,
.reasons-modal__input input {
  /* width: 100%; */
  /* padding: 8px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 4px; */
}

.reasons-modal__input-select {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  overflow-y: scroll;
  max-height: 450px;
}

/* Custom scrollbar */
.reasons-modal__input-select::-webkit-scrollbar {
  width: 20px;
}

.reasons-modal__input-select::-webkit-scrollbar-track {
  background: var(--general-general-40);
  border-radius: 10px;
}

.reasons-modal__input-select::-webkit-scrollbar-thumb {
  background-color: var(--primary-primary-70);
  border-radius: 10px;
  box-shadow: inset 0 4px 16px rgba(0, 0, 0, 0.1);
}

.reasons-modal__input-select::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-primary-80);
}

.reasons-modal__input-select .reasons-modal__input-select-option {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2.5px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 500;
  border-radius: 25px;
  text-align: center;
  border: 1px solid var(--brand-color);
}

.reasons-modal__input-select .reasons-modal__input-select-option.selected {
  background-color: var(--brand-color);
  color: var(--general-general-30);
}


.reasons-modal__input-select-option {
  
}

.reasons-modal__actions {
  display: flex;
  justify-content: space-around;
  gap: 32px;
  margin-top: 64px;
}

.reasons-modal__actions button {  
  cursor: pointer;
}
.reasons-kpi-view__content {
}
.reasons-kpi-view__reason {
  width: 100%;
  height: 100%;
  background-color: var(--general-general-30);
  padding: 10px 20px;
  border: 1px solid var(--primary-primary-40);
  border-radius: 5px;
  font-size: 16px;
}
/* status */
.kpi-view__status {
}

.status-kpi-view {
  padding: 32px 32px;
  width: 600px;
  height: min-content;
  border-radius: 16px;
  border: 2px solid var(--primary-primary-40);
  background: var(--general-general-30);
  display: flex;
  flex-direction: row;
  text-align: center;
}
.status-kpi-view__heading {
  /* margin-bottom: 32px; */
}
.status-kpi-view__title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}
.status-kpi-view__date {
  display: inline-block;
  color: var(--General-General-30, #f5f6fa);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 32px;
  padding: 8px 24px;
  background: var(--General-General-60, #a1a7c4);
}
.status-kpi-view__status-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default checkbox */
.status-kpi-view__status-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The track */
.status-toggle-slider {
  position: absolute;
  cursor: pointer;
  background-color: var(--general-general-60);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
}

/* The circular slider */
.status-toggle-slider::before {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

/* Toggled state */
.status-kpi-view__status-toggle input:checked + .status-toggle-slider {
  background-color: var(--system-red-1);
}

.status-kpi-view__status-toggle input:checked + .status-toggle-slider::before {
  transform: translateX(26px);
}
/* .many-elements .status-kpi-view {
  position: relative;
  width: unset;
  display: grid;
  grid-template-columns: 80% 20%;
}
.many-elements {
  height: 100%;
  flex: 0 0 600px;
}
.many-elements .status-kpi-view__heading {
  position: absolute;
  top: 15px;
  right: 15px;
}
.status-kpi-view__status.wide-status {
  
}
.many-elements .status-kpi-view__content {
  gap: clamp(10px, 10%, 100px);
  display: flex; 
}
.many-elements .status-kpi-view__options {
  flex: 1 0 auto;
}
.many-elements .status-kpi-view {
} */

/* NEW STATUS FOR 6+ OBJECTS*/
.many-elements {
  flex: 0 0 600px;
  display: flex;
  flex-direction: column;
}
.many-elements .kpi-view__status {
}
.many-elements .status-kpi-view {
  margin-top: 24px;
  position: relative;
  /* width: unset; */
  height: unset;
  display: block;
  flex-direction: unset;
  text-align: unset;

  flex: 1 1 auto;
}
.many-elements .status-kpi-view__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 40px;
}
.many-elements .status-kpi-view__status {
  width: unset;
  height: unset;
  min-width: unset;
  min-height: unset;
  max-width: 100%;
  max-height: 100%;
  flex: 0 1 60%;
  transition: background-color 0.5s ease-in-out;
}
.many-elements .status-kpi-view__options {
  flex-direction: row;

  /* flex: 1 1 auto; */
}
.many-elements .status-kpi-view__option {
}
.many-elements .status-kpi-view__heading {
  position: absolute;
  top: 15px;
  right: 15px;
}
.many-elements .flex-right {
}
.many-elements .full-width {
}
.many-elements .align-self-start {
}
.many-elements .status-kpi-view__title {
}
.many-elements .status-kpi-view__date {
}
/* NEW STATUS FOR 6+ OBJECTS*/

.status-kpi-view__status {
  /* margin: 0 auto; */
  width: max-content;
  min-height: 157px;
  /* flex: 1 1 auto; */
  border-radius: 50%;
  /* margin-bottom: 32px; */
  aspect-ratio: 1/1;
  transition: background-color 0.5s ease-in-out;
}
.status-kpi-view__dept-status {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: max-content;
  min-height: 109px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  transition: background-color 0.5s ease-in-out;
}

.status-kpi-view__sqcdp-status {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: max-content;
  height: 180px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  transition: background-color 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 700;
}

.status-kpi-view__sqcdp-status-trend {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.status-kpi-view__options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.status-kpi-view__option {
  width: 32px;
  height: 32px;
  font-size: 22px;
  line-height: 28px;
  padding-bottom: 4px;
  border: none;
  display: block;
  cursor: pointer;
  color: var(--general-general-30);
  border-radius: 50%;
  background: #f8ab40;
  /* transition: border 0.3s ease;
  transition: line-height 0.3s ease; */
  /* border: 4px solid var(--Primary-Primary-100, #1e5eff); */
}
.status-kpi-view__option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.status-kpi-view__option:hover,
.status-kpi-view__option:active {
  line-height: 24px;
  filter: brightness(1.2);
}

.departments-kpi-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  /* width: calc(100% - 636px); */
}

/* Skeleton Loading Styles */
.skeleton-loading {
  width: 100%;
  padding: 20px;
}

.skeleton-header {
  height: 40px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
  margin-bottom: 24px;
}

.skeleton-pacing {
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
}

.skeleton-status {
  width: 200px;
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 16px;
}

.skeleton-departments {
  flex: 1;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.skeleton-departments > div {
  width: 200px;
  height: 150px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 16px;
}

.skeleton-sqcpd {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.skeleton-letter {
  flex: 1;
  min-width: 300px;
  height: 400px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 16px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
