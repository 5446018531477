

.tag-color-box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}

.tag-name {
  font-weight: 500;
}

.loader {
  opacity: 0.5;
  pointer-events: none;
}
