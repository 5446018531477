.project-view-page {
  /* position: relative; */
  /* margin-left: -10%;*/
  padding-top: 20px;
  width: 3822px;
  min-width: 375px;
}

.project-view-page.huddle-boards-active {
  margin-left: 136px;
  width: 3550px;
}

.project-view-page-header {
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: var(--nav-bar-height);
  z-index: 1;
}

.project-view-page .project-view-page-title {
  /* font-size: 32px; */
  padding-left: calc(45px + 136px);
  /* font-weight: 500; */
  /* padding-bottom: 20px; */
  /* padding-top: 0; */
  /* margin: 0; */
  text-wrap-mode: nowrap;
}

.project-view-page.huddle-boards-active .project-view-page-title {
  padding-left: 45px;
}

.project-view-page-body-row {
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: center;
}

.project-view-page-body-row.hide-department {
  display: none !important;
}
.huddle-boards-active .project-view-page-body-row-header-1,
.huddle-boards-active .project-view-page-body-row-header-2,
.huddle-boards-active .project-view-page-body-row-header-department-1,
.huddle-boards-active .project-view-page-body-row-task-cell {
  width: 185px;
}

.project-view-page-body-row-header-1,
.project-view-page-body-row-header-2,
.project-view-page-body-row-header-department-1,
.project-view-page-body-row-task-cell {
  width: 202px;
}

.project-view-page-body-row-header-1,
.project-view-page-body-row-header-2,
.project-view-page-body-row-header-department-1 {
  color: rgba(51, 55, 82, 1);
  font-weight: 400;
  min-height: 178px;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.8px;
  border: 1px solid rgba(182, 203, 255, 1);
  background-color: rgba(230, 233, 244, 1);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-view-page-body-row-header-department-1.private-department-cell {
  background-color: #eddfff;
}

.project-view-page-body-row-header-1.due-column {
  background-color: rgba(215, 219, 236, 1) !important;
}

.project-view-page-body-row-header-department-1-view-icon {
  padding: 20px;
  position: relative;
  left: 222px;
  margin-left: -76px;
  margin-top: -86px;
  top: -26px;
  cursor: pointer;
}

.huddle-boards-active .project-view-page-body-row-header-department-1-view-icon {
  left: 209px;
}

/* .project-view-page.expand-tasks-active .project-view-page-body-row-header-department-1-view-icon {
  left: 790px;
} */

.project-view-page-body-row-header-department-1-view-icon img {
  width: 36px;
  height: 36px;
  filter: brightness(0) saturate(100%) invert(73%) sepia(5%) saturate(628%) hue-rotate(191deg)
}

.project-view-page-body-row-header-2 {
  flex-direction: column;
}

.project-view-page-body-row-header-2 .project-view-page-body-row-header-2-date {
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.8px;
  font-weight: 400;
}

.project-view-page-body-row-header-responsive-1 {
  display: none;
}

.project-view-page-body-row-header-responsive-2 {
  display: none;
}

.project-view-page-body-row-department-name {
  margin-left: -20px;
  width: 100%;
}

.project-view-page-body-row-task-cell {
  min-height: 100px;
  font-size: 20px;
  border: 1px solid rgba(182, 203, 255, 1);
  background-color: rgba(245, 246, 250, 1);
  padding: 5px;
}

.project-view-page-body-row-task-cell.no-tasks.empty-space-task-cell {
  display: none;
}

.btn-add-task {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: rgba(19, 21, 35, 1);

  border: none;
  cursor: pointer;
  font-size: 40px;
  margin: 10px;
}

.btn-add-task .btn-add-task-tooltip {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 80px;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: medium;
  margin-left: 25px;
  margin-top: 3px;
  transition: opacity 0.3s ease-in-out;
}

.btn-add-task {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  /* transition: box-shadow 0.3s ease-in-out; */
}

.btn-add-task:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

.btn-add-task:hover .btn-add-task-tooltip {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.project-view-page-refresh-in {
  color: lightgray;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-left: calc(45px + 136px);
  margin-top: 0px;
  min-width: 232px;
}

.huddle-boards-active .project-view-page-refresh-in {
  margin-left: 45px;
}

.project-title-bar {
  display: flex;
  flex-direction: row;
}

.project-title-bar .project-title-bar-right {
  margin-left: 40px;
  margin-right: 136px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.huddle-boards-active .project-title-bar .project-title-bar-right {
  margin-right: 0px;
}

.project-title-bar .project-title-bar-left {
  min-width: 185px;
}

.project-title-bar .project-title-bar-right-filter-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 16px;
}

@media (min-width: 1920px) {
  .project-title-bar-right .scrollable-filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
  }
}
/* don't change the order of the next three rules */
.project-title-bar .decision-mode-filter-responsive .project-title-bar-right-filter-button,
.project-title-bar .huddle-boards-filter-responsive .project-title-bar-right-filter-button,
.project-title-bar .completed-tasks-filter-responsive .project-title-bar-right-filter-button {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2.5px;
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  border-radius: 8px;
  text-align: center;
  margin-right: 16px;
  color: white;
  background-color: var(--main-color);
  width: 24px;
  height: 24px;
  align-items: center;
}

.project-title-bar .project-title-bar-right-filter-button,
/* .project-title-bar .project-title-bar-right-filter-button-department, */
.huddle-board-tag-filter .huddle-board-tag-filter-item {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2.5px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 500;
  border-radius: 25px;
  text-align: center;
  margin-right: 20px;
}

.project-title-bar .project-title-bar-right-filter-button-department {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2.5px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 500;
  border-radius: 25px;
  text-align: center;
  /* margin-right: 20px; */
}

.project-title-bar .project-title-bar-right-filter-button {
  color: white;
  background-color: var(--main-color);
  width: 242px;
}

/* don't change the order of this one */
.project-title-bar .decision-mode-filter-responsive .svg-inline--fa,
.project-title-bar .huddle-boards-filter-responsive .svg-inline--fa,
.project-title-bar .completed-tasks-filter-responsive .svg-inline--fa {
  color: white;
  background: none;
  font-size: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 0px;
  margin-bottom: 0px;
}
/* and this one */
.project-title-bar-right-filter-button .svg-inline--fa,
.mobile-scrollable-filters .svg-inline--fa {
  color: white;
  background: #333752;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 50%;
  margin-bottom: -2px;
}

.project-title-bar .project-title-bar-right-filter-button-department {
  color: rgba(19, 21, 35, 1);
}

.project-title-bar .decision-mode-filter-responsive,
.project-title-bar .huddle-boards-filter-responsive,
.project-title-bar .completed-tasks-filter-responsive {
  display: none;
}

.project-title-bar .project-title-bar-left-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-title-bar .project-title-bar-right-filter-button,
.project-title-bar .project-title-bar-right-filter-button-department,
.huddle-board-tag-filter .huddle-board-tag-filter-item {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  /* transition: box-shadow 0.3s ease-in-out;   */
}

.project-title-bar .project-title-bar-right-filter-button:hover,
.project-title-bar .project-title-bar-right-filter-button-department:hover,
.huddle-board-tag-filter .huddle-board-tag-filter-item:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

/* don't change the order of the next four rules */
.project-title-bar .decision-mode-filter-responsive .filter-active .svg-inline--fa {
  color: gray;
}

.project-title-bar
  .decision-mode-filter-responsive
  .project-title-bar-right-filter-button.filter-active,
.project-title-bar
  .huddle-boards-filter-responsive
  .project-title-bar-right-filter-button.filter-active,
.project-title-bar
  .completed-tasks-filter-responsive
  .project-title-bar-right-filter-button.filter-active {
  width: 24px;
}

.project-title-bar .filter-active {
  background-color: white !important;
  color: gray;
  border: 1px solid lightgray;
  box-shadow: none !important;
}

.project-title-bar .project-title-bar-right-filter-button.filter-active {
  width: 233px;
}

.project-title-bar .project-title-bar-right-filter-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.8px;
  margin-right: 20px;
  margin-left: 70px;
  padding-top: 4px;
}

/* START COMPLETED TASKS VIEW */
.completed-tasks-view-page .project-view-page-header,
.completed-tasks-view-page .project-view-page-body {
  width: min-content;
}

.completed-tasks-view-page .project-view-page-body-row-department-name {
  margin-left: 0px;
}

.completed-tasks-view-page .completed-tasks-view-page-sub-title {
  font-size: 23px;
  padding-left: calc(45px + 136px);
  font-weight: 200;
  padding-bottom: 10px;
  padding-top: 0;
  margin: 0;
}

@media (max-width: 1920px) {
  .completed-tasks-view-page .project-view-page-header,
  .completed-tasks-view-page .project-view-page-body {
    width: auto;
  }

  .completed-tasks-view-page .no-tasks {
    display: none;
  }

  .completed-tasks-view-page .completed-tasks-view-page-sub-title {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.3px;
    line-height: 160%;
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 10px;
    margin: 0;
  }
}
/* END COMPLETED TASKS VIEW */

/* START Nav Bar Project Buttons (Huddle Board Button, Edit Project Button, etc.) */
#project-nav-right-container {
  position: fixed;
  right: 291px;
  top: 16px;
  z-index: 5;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.project-nav-right-button {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #4f81ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-nav-right-button .svg-inline--fa {
  color: #d9e4ff;
  background: none;
  font-size: 47px;
}
/* END Nav Bar Project Buttons (Huddle Board Button, Edit Project Button, etc.) */
#kpi-view-button {
  border: 0px;
}
#kpi-view-button img {
  filter: brightness(0) saturate(100%) invert(84%) sepia(5%) saturate(1256%) hue-rotate(191deg)
    brightness(103%) contrast(106%);
}

@media (max-width: 1920px) {
  #project-nav-right-container {
    display: none;
  }
  .project-view-page {
    width: auto;
    padding-top: 76px;
    display: inline-block;
  }

  .btn-add-task-tooltip {
    display: none;
  }

  .project-view-page-body-row {
    flex-direction: column;
  }

  .project-view-page .project-view-page-title {
    /* font-size: 24px; */
    /* font-weight: 500; */
    /* letter-spacing: 0.3px; */
    /* line-height: 160%; */
    padding-left: 16px;

    padding-top: 0;
    margin: 0;
    text-wrap-mode: wrap;
    /* font-style: italic;   */
  }

  .project-view-page-refresh-in {
    color: #5a607f;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height: 120%;
    margin-left: 16px;
    margin-top: 0px;
    /* min-width: 232px; */
  }

  .decision-mode-filter {
    display: none !important;
  }

  .decision-mode-filter-responsive,
  /* .huddle-boards-filter-responsive, */
  .completed-tasks-filter-responsive {
    display: flex !important;
  }

  .project-title-bar .project-title-bar-right-filters-container {
    display: flex;
    flex-direction: row;
  }

  .project-view-page-body-row-header-responsive-1,
  .project-view-page-body-row-header-department-1,
  .project-view-page-body-row-task-cell {
    display: flex;
    width: auto;
    justify-content: flex-start;
  }
  .project-view-page-body-row-header-department-1 {
    border: 1px solid #b6cbff !important;
  }

  .project-view-page-body-row-header-department-1-view-icon {
    display: none;
  }

  .project-view-page-body-row-task-cell {
    border: none;
    border-left: 1px solid #b6cbff;
    border-right: 1px solid #b6cbff;
    min-height: unset;
    flex-direction: column;
    padding: 0px;
    padding-right: 16px;
    border-bottom: 1px dashed lightgray;
    /* flex-wrap: wrap; */
  }
  .project-view-page-header {
    display: none;
  }
  .project-view-page-body-row-header-1 {
    display: none;
  }

  .project-view-page-body-row-header-responsive-1,
  .project-view-page-body-row-header-responsive-2 {
    padding: 16px;
    display: flex;
    width: auto;
    min-width: 130px;
    justify-content: flex-start;
  }

  .project-view-page-body-row-header-responsive-1 div,
  .project-view-page-body-row-header-responsive-2 div {
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.8px;
    color: #5a607f;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }

  .task {
    max-width: 343px;
    padding-right: 16px;
  }

  .project-view-page-body-row-header-2 {
    display: none;
  }

  /* .no-tasks {
    display: none;
  } */

  .project-view-page-body-row-task-cell.no-tasks.empty-space-task-cell {
    height: 100%;
    display: flex;
  }

  .project-view-page-body-row-header-department-1 {
    border: none;
    border-top: 1px solid #b6cbff;
    border-bottom: 1px solid #b6cbff;
    min-width: 130px;
    background-color: #ecf2ff;
    min-height: 48px;
    padding: 0px;
  }

  .project-view-page-body-row-header-department-1 .btn-add-task {
    width: 24px;
    height: 24px;
    font-size: 16px;
    margin: 0px;
    margin-right: 16px;
  }

  .project-view-page-body-row-header-department-1 .btn-add-task-tooltip {
    display: none;
  }

  .project-view-page-body-row-department-name {
    min-width: calc(100% - 16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.8px;
  }

  .project-view-page-body-row-task-cell-tasks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .project-view-page-body-row-task-cell .project-view-page-body-row-task-cell-tasks {
    /* padding-left: 16px;
    padding-right:16px;
    padding-bottom: 16px;
    padding-top: 0px; */
    padding: 0px;
  }

  .project-title-bar {
    flex-direction: column;
    margin-bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    /* width: calc(100vw - 17px);     */
    width: 100vw;
  }

  .project-title-bar .project-title-bar-right {
    margin-left: 0px;
    flex-direction: column;
    align-items: unset;
  }

  .project-title-bar .project-title-bar-right-filter-title {
    display: none;
  }

  .project-title-bar .mobile-scrollable-filters {
    padding: 16px !important;
    margin-bottom: 8px;
    /* padding: 16px; */
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    user-select: none;
  }

  .project-title-bar .mobile-scrollable-filters::-webkit-scrollbar {
    height: 1px;
    background: transparent;
  }

  .project-title-bar .project-title-bar-right-filter-button-department {
    white-space: nowrap;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.8px;
    border-radius: 12px;
    margin-right: 8px;
    padding-right: 8px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
  }

  .project-title-bar .project-title-bar-right-filter-button-department .svg-inline--fa {
    font-size: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 0px;
  }

  .huddle-boards-active .project-title-bar-right,
  .huddle-boards-active .project-view-page-body,
  .huddle-boards-active .decision-mode-filter-responsive {
    display: none !important;
  }

  .project-view-page-body {
    display: flex;
    flex-direction: row;
  }

  .project-view-page.huddle-boards-active {
    margin-left: 0px;
    width: auto;
  }

  .project-view-page.huddle-boards-active .project-view-page-title {
    padding-left: 16px;
  }

  .huddle-boards-active .project-view-page-refresh-in {
    margin-left: 16px;
  }
}

@media (max-width: 780px) {
  /* .no-tasks {
    display: none;
  } */

  .project-view-page-body {
    display: flex;
    flex-direction: column;
  }

  .project-view-page-body-row-task-cell {
    border: 1px solid lightgray;
    padding: 0px;
    padding-right: 0px;
  }
  .project-view-page-body-row-task-cell.no-tasks {
    visibility: hidden;
    height: 0px;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  .project-view-page-body-row-task-cell.no-tasks.empty-space-task-cell {
    display: none;
  }

  .project-view-page-body-row-header-department-1 {
    border-left: none;
    border-right: none;
  }
}
