.archive-page .reseed-btn {
  width: auto;
}

.archive-page {
  padding: 120px 0px 0px;
}

.archive-page .project-row {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1920.98px) {
  .archive-page {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 70px 10px;
  }
}
@media screen and (max-width: 768.98px) {
  .archive-page {
    padding: calc(var(--nav-bar-height-mobile) + 32px) 10px 40px 10px;
  }
}
