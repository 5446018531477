.department-users-edit-page {
  /* width: 80%;
  margin: 0 auto; */
  grid-column: 1 span;
}

/* .department-users-edit-page .department-users-list-container {
  display: flex;
  flex-direction: row;
} */

/* .department-users-list {
  margin: 17px 0;
  margin-right: 40px;
  padding: 12px 12px;
  border-radius: 5px;
  background-color: #D9D9D9;
  min-width: 480px;
  max-width: 778px;
} */

.department-users-list .department-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.department-users-form .user-input {
  background-color: var(--general-general-30);
  border: 1px solid var(--primary-primary-100);
  box-shadow: 0px 5px 16px rgba(119, 133, 238, 0.3);
  border-radius: 16px;
  padding: 9.5px 24px;
  color: var(--general-general-100);
}
.department-users-list .user-name {
  font-weight: bold;
  margin-right: 20px;
  margin-left: 10px;
}

.department-user {
  border-bottom: 1px solid black;
  padding: 20px 0;
}
.department-user:last-child {
  border-bottom: 0;
}

.department-users-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.department-users-list-item .user-info-container {
  display: flex;
  flex-direction: column;
}

.department-users-form {
  border-radius: 10px;
}

.department-users-form .btn {
  width: auto;
}

.department-users-form .label {
  width: auto;
  margin: 0;
}

@media (max-width: 1120px) {
  .department-users-edit-page .department-users-list-container {
    flex-direction: column;
    align-items: center;
  }
  .department-users-edit-page .form-actions {
    align-items: center;
    flex-direction: column;
    /* max-width: 430px;     */
  }
  .department-users-edit-page .form-actions .btn {
    width: auto;
  }

  .department-users-list {
    margin-right: 0;
    min-width: 300px;
  }

  .department-users-form {
    margin-right: 0;
  }

  .department-users-list .department-user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* 




.department-users-list .btn {
  width: auto;
}

.users-list-item:last-of-type {
  border-bottom: none;
  margin-bottom: 20px;
}

.add-user-form {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #f5f5f5;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.departments-users-edit-page {
  width: 80%;
  margin: 0 auto;
} */
