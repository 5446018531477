@font-face {
  font-family: 'Reenie-Beanie';
  src: local('Reenie-Beanie'), url(./fonts/ReenieBeanie-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Klyakson';
  src: local('Klyakson'), url(./fonts/Klyakson.ttf) format('truetype');
}

@font-face {
  font-family: 'VCR-OSD-MONO';
  src: local('VCR-OSD-MONO'), url(./fonts/VCR-OSD-MONO.ttf) format('truetype');
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
  font-family: 'Poppins', sans-serif;
  background-color: var(--general-general-30);
}

:root {
  --brand-color: #1e5eff;
  --main-color: #336dff;
  --gray-color: #e6e9f4;
  --yellow-color: #ffdd00;
  --black-color: rgba(19, 21, 35, 1);
  --danger-color: #ff7043;
  --accept-color: rgba(91, 249, 138, 1);
  --dark-accept-color: rgb(31, 206, 83);
  --highlight-color: #729aff;
  --light-color: #d9e4ff;
  --huddle-board-bg-color: #f5f6fa;
  --default-font: 'Poppins', sans-serif;
  --nav-bar-height: 128px;
  --nav-bar-height-mobile: 60px;
  --huddle-board-center-width: 2302px;
  --huddle-board-center-height: 1592px;

  /* Design colors */
  --system-red-1: #f85640;
  --system-red-2: #f8634e;
  --system-red-3: #f97462;
  --system-green-1: #259225;
  --system-green-2: #29a329;
  --system-green-3: #2bab2b;
  --departments-pm: #ccfcb7;
  --departments-tt: #cbc2e5;
  --departments-ad: #d2e8fe;
  --departments-qa: #ffedb8;
  --departments-r-d: #fecdfe;
  --departments-pro: #ffe5d4;
  --departments-eng: #fdd6b8;
  --departments-qc: #dfd9d9;
  --departments-client: #fec2c2;
  --departments-additional-dep-1: #e5fff8;
  --departments-additional-dep-2: #e5feb6;
  --departments-additional-dep-3: #d9d3ff;
  --departments-text-pm: #395030;
  --departments-text-tt: #393050;
  --departments-text-ad: #303f50;
  --departments-text-qa: #504830;
  --departments-text-r-d: #503050;
  --departments-text-pro: #503c30;
  --departments-text-eng: #503e30;
  --departments-text-qc: #503030;
  --departments-text-client: #503030;
  --departments-text-additional-dep-1: #305047;
  --departments-text-additional-dep-2: #455030;
  --departments-text-additional-dep-3: #353050;
  --primary-primary-100: #1e5eff;
  --primary-primary-90: #336dff;
  --primary-primary-80: #4f81ff;
  --primary-primary-70: #608dff;
  --primary-primary-60: #89abff;
  --primary-primary-50: #b6cbff;
  --primary-primary-40: #d9e4ff;
  --primary-primary-30: #ecf2ff;
  --general-gray: #979797;
  --general-general-100: #131523;
  --general-general-90: #333752;
  --general-general-80: #5a607f;
  --general-general-70: #7e84a3;
  --general-general-60: #a1a7c4;
  --general-general-50: #d7dbec;
  --general-general-40: #e6e9f4;
  --general-general-30: #f5f6fa;
  --system-red-1-filter: brightness(0) saturate(100%) invert(36%) sepia(98%) saturate(2616%)
    hue-rotate(342deg) brightness(121%) contrast(94%);
  --system-green-1-filter: brightness(0) saturate(100%) invert(33%) sepia(58%) saturate(5480%)
    hue-rotate(111deg) brightness(99%) contrast(71%);
  --system-red-3-filter: brightness(0) saturate(100%) invert(52%) sepia(81%) saturate(1479%)
    hue-rotate(324deg) brightness(112%) contrast(122%);
  --system-green-1-filter: brightness(0) saturate(100%) invert(42%) sepia(21%) saturate(7442%)
    hue-rotate(93deg) brightness(90%) contrast(71%);
  --system-green-3-filter: brightness(0) saturate(100%) invert(43%) sepia(87%) saturate(551%)
    hue-rotate(73deg) brightness(104%) contrast(84%);
  --primary-primary-30-filter: brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(1507%)
    hue-rotate(198deg) brightness(104%) contrast(96%);
  --primary-primary-40-filter: brightness(0) saturate(100%) invert(84%) sepia(99%) saturate(7048%)
    hue-rotate(178deg) brightness(100%) contrast(107%);
  --primary-primary-80-filter: brightness(0) saturate(100%) invert(45%) sepia(88%) saturate(2581%)
    hue-rotate(208deg) brightness(102%) contrast(102%);
  --general-general-30-filter: brightness(0) saturate(100%) invert(95%) sepia(23%) saturate(2475%)
    hue-rotate(204deg) brightness(130%) contrast(95%);
  /* Design colors */
}

a {
  text-decoration: none;
}

input,
textarea,
.btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  box-sizing: border-box;
  background-color: lightgray;
}

.btn {
  text-align: center;
  color: black;
  cursor: pointer;
}

input:hover,
textarea:hover,
.btn:hover {
  opacity: 1;
}

.submit {
  background-color: gray;
  color: white;
}

.bold {
  font-weight: bold;
}

.text-light {
  color: gray;
}

.error {
  color: red;
  margin: 0;
}

.error-center {
  color: red;
  margin: 0;
  text-align: center;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  /* cursor: not-allowed; */
}

.active {
  background-color: red;
  /* transition: background-color 100ms linear; */
}

.loader {
  background-color: var(--primary-primary-30);
  color: var(--general-general-80, black);
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-center {
  align-items: center;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.half-width {
  width: 50%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.mr-20 {
  margin-right: 20px;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-gap-16 {
  gap: 16px;
}
.flex-gap-24 {
  gap: 24px;
}
.flex-gap-36 {
  gap: 36px;
}
.margin-right-sm {
  margin-right: 20px;
}
.margin-bottom-sm {
  margin-bottom: 20px;
}
.margin-bottom-md {
  margin-bottom: 40px;
}
.margin-right-md {
  margin-right: 40px;
}
.gap-sm {
  column-gap: 20px;
}
.gap-md {
  column-gap: 40px;
}
.adaptive-container {
  max-width: 3520px;
  margin: 0 auto 30px auto;
  padding: 0px;
}

.invisible {
  visibility: hidden !important;
  height: 0 !important;
  width: 0 !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* color picker */
.color-button {
  width: 80px;
  height: 80px;
  border-radius: 32px;
  border: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}
.color-button.active-color {
  border: 2px solid var(--primary-primary-30);
}
.color-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media screen and (max-width: 1024px) {
  .color-picker .color-button {
    width: 60px;
    height: 60px;
    border-radius: 24px;
  }
}
/* color picker */
@media (max-width: 1920.98px) {
  .adaptive-container {
    padding: 0px 10px 30px;
    max-width: 1500px;
  }
}
@media (max-width: 1440.98px) {
  .adaptive-container {
    max-width: 1200px;
  }
}

@media (max-width: 1200.98px) {
  .adaptive-container {
    max-width: 970px;
  }
}

@media (max-width: 1024.98px) {
  .adaptive-container {
    max-width: 900px;
  }
}

@media (max-width: 767.98px) {
  .adaptive-container {
    max-width: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.Material-KitH1 {
  font-family: inherit;
  font-size: 192px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -3px;
}

.Material-KitH2 {
  font-family: inherit;
  font-size: 120px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1px;
}

.Material-KitH3 {
  font-family: inherit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.Material-KitH4 {
  font-family: inherit;
  font-size: 68px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

.Material-KitH5 {
  font-family: inherit;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.Material-KitH6 {
  font-family: inherit;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.Material-KitHB6 {
  font-family: inherit;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.Material-KitHB6_S {
  font-family: inherit;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.Material-KitHB6_TS {
  font-family: inherit;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.Material-KitSB1 {
  font-family: inherit;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.3px;
}

.Material-KitSB1_S {
  font-family: inherit;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: unset;
}

.Material-KitB1 {
  font-family: inherit;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
}

.Material-KitSB2_AC {
  font-family: inherit;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

.Material-KitSB2 {
  font-family: inherit;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

.Material-KitSB2_TS {
  font-family: inherit;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: unset;
}

.Material-KitButton {
  font-family: inherit;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 2.5px;
}

.Material-Kit64-90-Custom {
  font-family: inherit;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.Material-KitB2 {
  font-family: inherit;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.Material-KitCaption {
  font-family: inherit;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
}

.Material-KitOverline {
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 3px;
}

.Material-KitLabel-1 {
  color: var(--general-general-80, #5a607f);
  text-align: center;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

/* Tablet Styles */
@media screen and (max-width: 1024.98px) {
  .Material-KitH1 {
    font-size: 128px;
  }

  .Material-KitH2 {
    font-size: 96px;
  }

  .Material-KitH3 {
    font-size: 72px;
  }

  .Material-KitH4 {
    font-size: 56px;
  }

  .Material-KitH5 {
    font-size: 40px;
  }

  .Material-KitH6 {
    font-size: 32px;
  }

  .Material-KitSB1,
  .Material-KitB1 {
    font-size: 28px;
  }

  .Material-KitSB2,
  .Material-KitSB2_AC,
  .Material-KitButton {
    font-size: 24px;
  }

  .Material-KitB2 {
    font-size: 24px;
  }

  .Material-KitCaption {
    font-size: 20px;
  }

  .Material-KitOverline {
    font-size: 18px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768.98px) {
  .Material-KitH1 {
    font-size: 72px;
    letter-spacing: -2px;
  }

  .Material-KitH2 {
    font-size: 56px;
    letter-spacing: -1px;
  }

  .Material-KitH3 {
    font-size: 48px;
  }

  .Material-KitH4 {
    font-size: 36px;
  }

  .Material-KitH5 {
    font-size: 28px;
  }

  .Material-KitH6 {
    font-size: 24px;
  }

  .Material-KitSB1,
  .Material-KitB1 {
    font-size: 20px;
  }

  .Material-KitSB2,
  .Material-KitSB2_AC,
  .Material-KitButton {
    font-size: 18px;
    letter-spacing: 1.5px;
  }

  .Material-KitB2 {
    font-size: 18px;
  }

  .Material-KitCaption {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .Material-KitOverline {
    font-size: 14px;
    letter-spacing: 2px;
  }
}

/* Small Mobile Styles */
@media screen and (max-width: 480.98px) {
  .Material-KitH1 {
    font-size: 48px;
    letter-spacing: -1px;
  }

  .Material-KitH2 {
    font-size: 40px;
  }

  .Material-KitH3 {
    font-size: 32px;
  }

  .Material-KitH4 {
    font-size: 28px;
  }

  .Material-KitH5 {
    font-size: 20px;
  }

  .Material-KitH6 {
    font-size: 20px;
  }

  .Material-KitSB1,
  .Material-KitB1 {
    font-size: 18px;
  }

  .Material-KitSB2,
  .Material-KitSB2_AC,
  .Material-KitButton {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .Material-KitB2 {
    font-size: 16px;
  }

  .Material-KitCaption {
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  .Material-KitOverline {
    font-size: 12px;
    letter-spacing: 1.5px;
  }
}

/* nullstyle */
/* *,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  color: inherit;
  display: inline-block;
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
 */
