.note {
  background-color: var(--gray-color);
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  width: auto;
  padding: 16px;
}
.note__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.note__textarea {
  width: 100%;
  height: 100%;
  /* border: 1px solid transparent;
  transition: border 0.3s ease;
   */
  resize: none;
  background-color: transparent;
}
.note__textarea:not(:disabled) {
  background-color: #fff;
}
.note__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.note__button {
  background-color: var(--gray-color);
  border: none;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: filter 0.3s ease;
}
.note__button.button-cancel img {
  filter: var(--system-red-1-filter);
}
.note__button.button-cancel:hover img,
.note__button.button-cancel:active img {
  filter: var(--system-red-3-filter);
}

.note__button.button-submit img {
  filter: var(--system-green-1-filter);
}
.note__button.button-submit:hover img,
.note__button.button-submit:active img {
  filter: var(--system-green-3-filter);
}
.note__button.button-edit:hover img,
.note__button.button-edit:active img {
  filter: var(--primary-primary-80-filter);
}
.note__button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
