/* DAILY AGENDA */

.da_actions {
    width: 10%;
    text-align: center;
}

.da_topic {
    width: 65%;
    text-align: center;
}

.da_duration {
    width: 15%;
    text-align: center;
}

.da_status {
    width: 10%;
    text-align: center;
}
.huddle-board-content-row.daily-agenda-container {
    margin-top: 20px;
    gap: 50px;
    align-items: flex-start;
    /* margin-bottom: 350px; */
}
.huddle-board-content-row.daily-agenda-container .huddle-board-content-metric {
    width: 600px;
}
.daily-agenda .table-wrapper {
    width: 100%;
    padding: 0;
}
.daily-agenda .table {    
    height: 650px;
}

.daily-agenda .table tbody {
    height: 574px;
}

.daily-agenda .table tbody tr td .complete-btn.svg-inline--fa.completed {
    color: green;
}

.daily-agenda .table tbody tr td .svg-inline--fa {
    cursor: pointer;
    color: var(--gray-color);
}

.daily-agenda .table tbody tr td.da_actions .svg-inline--fa:hover {
    cursor: pointer;
    color: var(--black-color);
}

.daily-agenda .table tbody tr td.da_status .svg-inline--fa:hover {
    cursor: pointer;
    color: var(--dark-accept-color);
}

.daily-agenda .table tbody tr.completed {
    background-color: rgb(193, 247, 193);
}

.daily-agenda-deep-dive {
    margin-top: 10px;    
}

.daily-agenda-btn-container {
    /* padding-left: 120px;
    padding-right: 120px; */
    margin-left: auto;
    margin-right: auto;
}

.daily-agenda-btn-container .btn-add {
    width: auto;
    height: 70px;
    border-radius: 35px;
    background-color: var(--dark-accept-color);
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    font-family: var(--default-font);
}

.daily-agenda-deep-dive-table {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;    
    align-items: flex-start;    
    color: #222;
    
}

.daily-agenda-deep-dive-table .deep-dive-row {
    width: 20%;
    /* border: 1px solid white;     */
    border-left: 1px solid #ccc;
    height: 250px;
    border-collapse: collapse;
}

.daily-agenda-deep-dive-table .deep-dive-row .deep-dive-heading {
    background-color: #ccc;
    padding: 10px;    
}

.daily-agenda-deep-dive-table .deep-dive-row .deep-dive-content {
    padding: 10px;
}

.daily-agenda-deep-dive-table .daily-agenda-deep-dive-edit-form {
    display: inherit;
    width: 100%;
    min-width: 53vw;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.daily-agenda-deep-dive-table .deep-dive-content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    font-weight: 400;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;        
}

.daily-agenda-deep-dive-table .deep-dive-content-edit input, .daily-agenda-deep-dive-table .deep-dive-content-edit textarea {
    font-weight: 400;
    background-color: white;
    color: #222;
    font-family: var(--default-font);
    opacity: 1;
    font-size: 1em;
    padding: 0px;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit textarea {
    border-radius: 8px;
    border: none;
    background-color: white;
    line-height: 1.5em;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit .edit-form-buttons {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 50px;    
    gap: 40px;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit .edit-form-buttons .svg-inline--fa {
    font-size: 2.5em;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit .edit-form-buttons .edit-submit-button:hover {
    opacity: 1;
}

.daily-agenda-deep-dive-table .deep-dive-content-edit .edit-form-buttons .edit-submit-button {    
    background-color: unset;    
    border: 0px;
    cursor: pointer;
    opacity: 0.1;
}

.daily-agenda .global-edit-indicator, .attendance .global-edit-indicator {
    margin-top: 20px;
    margin-bottom: -20px;
    font-size: 0.7em;
    font-weight: 400;
    color: var(--black-color);
}


/* END OF DAILY AGENDA */



/* ATTENDANCE */

.atnd_actions {
    width: 5%;
    text-align: center;
}

.atnd_names {
    width: 45%;
    text-align: center;
}

.atnd_department {
    width: 35%;
    text-align: center;
}

.atnd_status {
    width: 15%;
    text-align: center;
}

.huddle-board-content-row.attendance-container {
    margin-top: 50px;
    gap: 50px;
    align-items: flex-start;
    /* margin-bottom: 350px; */
}
.huddle-board-content-row.attendance-container .huddle-board-content-metric {
    width: 700px;
}
.attendance .table-wrapper {
    width: 100%;
    padding: 0;
}
.attendance .table {    
    height: 900px;
}

.attendance .table tbody {
    height: 824px;
}

.attendance .table tbody tr td .complete-btn.svg-inline--fa.present {
    color: green;
}

.attendance .table tbody tr td .svg-inline--fa {
    cursor: pointer;
    color: var(--gray-color);
}

.attendance .table tbody tr td.da_actions .svg-inline--fa:hover {
    cursor: pointer;
    color: var(--black-color);
}

.attendance .table tbody tr td.da_status .svg-inline--fa:hover {
    cursor: pointer;
    color: var(--dark-accept-color);
}

.attendance .table tbody tr.present {
    background-color: rgb(193, 247, 193);
}

.attendance-deep-dive {
    margin-top: 20px;
    position: fixed;
    margin-right: 100px;
}

.attendance-btn-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.attendance-btn-container .btn-add {
    width: auto;
    height: 70px;
    border-radius: 35px;
    background-color: var(--dark-accept-color);
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    font-family: var(--default-font);
}

/* END OF ATTENDANCE */