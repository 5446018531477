.tags-edit-page {
  /* width: 80%;
  margin: 0 auto; */
  grid-column: 1 span;
}
.tags-edit-page .form-actions {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-top: 60px;
  margin-bottom: 40px;
  /* justify-content: center; */
  /* min-width: 320px; */
  /* max-width: 966px; */
}
@media (max-width: 1120px) {
  .tags-edit-page .form-actions {
    align-items: center;
    flex-direction: column;
    /* max-width: 430px;     */
  }
  .tags-edit-page .form-actions .btn {
    width: auto;
  }
}
