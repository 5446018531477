/* MANY TASKS EXPAND */
.project-view-page.expand-tasks-active {
  margin-left: 640px;  
}
.project-view-page.expand-tasks-active .many-tasks-container.expanded-many-tasks-container {  
  left: 0px;
}
.project-view-page.huddle-boards-active.expand-tasks-active .many-tasks-container.expanded-many-tasks-container {
  left: 136px;
}
.project-view-page.huddle-boards-active.expand-tasks-active {
  margin-left: 776px;
  width: 3686px;
}
.project-view-page .many-tasks-container {
  display: none;
}
.project-view-page .many-tasks-container.expanded-many-tasks-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #D9E4FF;
  position: fixed;  
  top: 128px;
  height: calc(100vh - 207px);
  width: 576px;
  padding: 32px;
  z-index: 1;
}

.expanded-many-tasks-header-container {
  margin-bottom: 32px;
}

.expanded-many-tasks-header-container .expanded-many-tasks-header-close-button {
  text-align: right;
  cursor: pointer;
  font-size: 28px;
}

.expanded-many-tasks-header-container .expanded-many-tasks-header-department {
  font-size: 48px;
  line-height: 140%;
  font-weight: 500;
}

.expanded-many-tasks-header-container .expanded-many-tasks-header-column {
  font-size: 24px;
  line-height: 140%;
  font-weight: 400;
}

.expanded-many-tasks-list {    
  overflow: auto;
  padding-right: 32px;  
}

/* width */
.expanded-many-tasks-list::-webkit-scrollbar {
  width: 20px;  
}

/* Track */
.expanded-many-tasks-list::-webkit-scrollbar-track {
  background: #E6E9F4;
  border-radius: 32px;
  
}

/* Handle */
.expanded-many-tasks-list::-webkit-scrollbar-thumb {
  background: #608DFF;
  border-radius: 32px;
}

/* Handle on hover */
.expanded-many-tasks-list::-webkit-scrollbar-thumb:hover {
  background: #4F81FF;
}

@media (max-width: 1920px) {  
  .project-view-page-body-row-task-cell-tasks-stack .many-tasks,
  .project-view-page-body-row-task-cell-tasks .expanded-many-tasks-header-container {
    display: none;
  }
  .project-view-page .many-tasks-container {
    display: flex;    
  }

  .project-view-page .many-tasks-container .expanded-many-tasks-list {
    padding-right: 0px;
    overflow: unset;
    display: flex;
    flex-wrap: wrap;
  }

}

/* END MANY TASKS EXPAND */



.many-tasks {
  height: 150px;  
}

.many-tasks .stack-task-box {
  background-color: #f0f0f0;
  height: auto;
  min-height: 100px;
  line-height: 22px;    
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  min-width: 100px;
  position: relative;  
}

.many-tasks .stack-bottom {
  opacity: 0.5;
  margin: 5px 19px 10px 5px;
}

.many-tasks .stack-middle {
  opacity: 0.7;
  margin: 5px 19px 10px 5px;
  top: -120px;
  left: 7px;
}

.many-tasks .stack-top {
  opacity: 1;
  margin: 5px 19px 10px 5px;
  top: -240px;
  left: 14px;  
}

.many-tasks.expand .stack-top,
.many-tasks.expand .stack-middle,
.many-tasks.expand .stack-bottom {
  background-color: var(--brand-color) !important;
}

.many-tasks.expand .stack-task-box-text {
  color: white !important;
}

.many-tasks div.stack-task-box-text {
  color: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15); 
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
}

.many-tasks div.stack-task-box-text:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}



@media (max-width: 1920px) {  
  .many-tasks .stack-task-box {
    
    width: 322px;    
    margin-left: 16px;
    /* margin-right: 16px; */
    margin-bottom: 16px;
    margin-top: 0px;
  }
}