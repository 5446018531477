.list-users__account {
}
.account-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-primary-40);
  border-radius: 16px;
  padding: 53.5px 64px;
  max-width: 100%;
  overflow: hidden;
}
.account-users.selected {
  background: var(--Primary-Primary-50, #b6cbff);
}
.account-users:not(:last-child) {
  margin-bottom: 32px;
}
.account-users__info {
  flex: 1 1 auto;
  display: flex;
}
.account-users__info-text {
  text-align: left;
}
.account-users__info-text h5 {
  margin-bottom: 8px;
}
.account-users__info-text span {
  margin: 0;
}
.account-users__image {
  width: 120px;
  height: 120px;
  margin-right: 32px;
}
.account-users__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.account-users__image .account-initial {
  font-size: 36px;
  background-color: white;
  height: 100px;
  width: 100px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.account-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
}
.account-page .btn-account-page-tooltip {
  text-transform: none;
}

.account-page .button__image {
  width: 100px;
  height: 100px;
}

.account-page .btn-account-page-tooltip {
  margin-top: 224px;
  margin-left: -10px;
}

.account-page__info {
  /* flex: 1 1 auto; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  gap: 32px;
}

.account-page__info-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.account-page__image {
  width: auto;
  height: auto;  
}

.account-page__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.account-page__actions {
  align-self: center;
}

.button-view.alone {
  margin-right: 0px;
}
.account-users__actions { 
}

/* .accounts-page .account-users__actions {
  width: 470px;
} */
.button-view {
  margin-right: 24px;
  background-color: var(--primary-primary-80);
}
.button-view__image {
}
.button__image {
  width: 56px;
  height: 56px;
}
.button-delete {
  background-color: var(--system-red-1);
}
.button-delete__image {
}

@media screen and (max-width: 1920px) {
  .accounts-page .account-users {    
    flex-direction: column;    
    gap: 20px;
  }
  .accounts-page .account-users__info {
    align-self: flex-start;
  }
  .accounts-page .account-users__actions {
    align-self: flex-end;
  }
  .accounts-page .users__menu {    
    margin-bottom: 36px;
  }

  .accounts-page .users__row {
    column-gap: unset;    
  }
}

@media screen and (max-width: 1200px) {
  .account-users {
    flex-direction: column;
    align-items: unset;
    gap: 20px;
  }
  .account-users__actions {
    align-self: flex-end;
  }
  .account-page .button__image {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 1024.98px) {
  .button__image {
    width: 38px;
    height: 38px;
  }
  .account-users {
    padding: 26px 30px;
  }
}
@media screen and (max-width: 768.98px) {
  .account-users {
    padding: 16px 20px;
    flex-wrap: wrap;
  }
  .account-users__info {
    justify-content: flex-start;
    width: 100%;
  }
  .account-users__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .account-users {
    column-gap: 16px;
  }
  .account-users__image {
    /* width: 80px;
    height: 80px;
    margin-right: 10px; */
    display: none;
  }
  .button-view {
    background-color: transparent;
  }
  .button-view:active .button-view__image img {
    filter: var(--primary-primary-30-filter);
  }
  .button-delete {
    background-color: transparent;
  }
  .button-delete__image img {
    filter: var(--system-red-1-filter);
  }
  .button-delete:active .button-delete__image img {
    filter: var(--primary-primary-80-filter);
  }
}
