.task-form,
.modal.task-form {
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  border-radius: 16px;
  box-shadow: 10px 10px 40px #00000069;
  z-index: 1000;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 680px;
  text-align: left;
}

.new-task-form-container {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: 10px;
}

.edit-task-form-container {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 10px;
}

.task-form-background-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.task-form .error {
  margin-top: -20px;
  margin-bottom: 40px;
  border-radius: 16px;
  padding: 8px 24px;
}

.task-form .label {
  width: auto;
  margin: 0;
}

.task-form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-form .task-form-title {
  font-size: 40px;
  line-height: 140%;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0px 0px 16px 0px;
}

.edit-task-form-container form,
.task-form-view-only-container {
  margin-top: 64px;
}

.edit-task-form-container .task-form-audit,
.edit-task-form-container .task-form-buttons-admin-confirm-text {
  font-family: var(--font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.8px;
}

.task-form-department,
.task-form-assigned-to,
.task-form-due-date,
.task-form-tag {
  display: flex;
  padding: 12px 32px;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  width: fit-content;

  color: #395030;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 2.5px;

  text-transform: uppercase;
  transition: box-shadow 0.3s ease;
}

.task-form-assigned-to-none,
.task-form-tag-none {
  display: flex;
  padding: 12px 32px;
  align-items: center;
  width: fit-content;

  color: #395030;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 2.5px;

  text-transform: uppercase;
}

.task-form-department:hover,
.task-form-assigned-to:hover,
.task-form-due-date:hover,
.task-form-tag:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease;
}

.task-form-assigned-to-selected,
.task-form-department-selected,
.task-form-due-date-selected,
.task-form-tag-selected {
  background-color: #ecf2ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.task-form-assigned-to-selected .fa-xmark,
.task-form-department-selected .fa-xmark,
.task-form-due-date-selected .fa-xmark,
.task-form-tag-selected .fa-xmark {
  color: white;
  background: #333752;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 50%;
  cursor: pointer;
}

.task-form-assigned-to-container .fa-pen,
.task-form-department-container .fa-pen,
.task-form-due-date-container .fa-pen,
.task-form-tag-container .fa-pen {
  color: #a1a7c4;
  font-size: 30px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #a1a7c4;
  cursor: pointer;
}

.task-form-assigned-to-container .fa-pen.editing,
.task-form-department-container .fa-pen.editing,
.task-form-due-date-container .fa-pen.editing,
.task-form-tag-container .fa-pen.editing {
  color: white;
  background-color: var(--brand-color);
  border: 1px solid var(--brand-color);
}

.task-form-department-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  color: #a1a7c4;
  font-size: 48px;
  margin-bottom: 40px;
}

.task-form-assigned-to-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  color: #a1a7c4;
  font-size: 48px;
  margin-bottom: 40px;
}

.task-form-tag-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  color: #a1a7c4;
  font-size: 48px;
  margin-bottom: 40px;
}

.task-form-assigned-to-list,
.task-form-department-list,
.task-form-tag-list {
  position: absolute;
  margin-left: -14px;
  bottom: 191px;
  width: 480px;
  flex-direction: column;
  display: flex;
  gap: 20px;
  padding: 24px 24px;
  border-radius: 16px;
  border: 1px solid var(--brand-color);
  background-color: #f5f6fa;
  height: 320px;
  overflow: auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.task-form-due-date-list {
  position: absolute;
  margin-left: -14px;
  bottom: 191px;
  z-index: 2;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

/* width */
.task-form-assigned-to-list::-webkit-scrollbar,
.task-form-department-list::-webkit-scrollbar,
.task-form-tag-list::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.task-form-assigned-to-list::-webkit-scrollbar-track,
.task-form-department-list::-webkit-scrollbar-track,
.task-form-tag-list::-webkit-scrollbar-track {
  background: #e6e9f4;
  border-radius: 16px;
  margin: 3px;
}

/* Handle */
.task-form-assigned-to-list::-webkit-scrollbar-thumb,
.task-form-department-list::-webkit-scrollbar-thumb,
.task-form-tag-list::-webkit-scrollbar-thumb {
  background: #608dff;
  border-radius: 16px;
}

/* Handle on hover */
.task-form-assigned-to-list::-webkit-scrollbar-thumb:hover,
.task-form-department-list::-webkit-scrollbar-thumb:hover,
.task-form-tag-list::-webkit-scrollbar-thumb:hover {
  background: #4f81ff;
}

.task-form-assigned-to-list .task-form-assigned-to,
.task-form-department-list .task-form-department,
.task-form-tag-list .task-form-tag {
  cursor: pointer;
  background-color: white;
}

.task-form-assigned-to-list .task-form-assigned-to.task-form-assigned-to-selected,
.task-form-department-list .task-form-department.task-form-department-selected,
.task-form-tag-list .task-form-tag.task-form-tag-selected {
  cursor: default;
  background-color: #ecf2ff;
}

.task-form-due-date-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  color: #a1a7c4;
  font-size: 48px;
  margin-bottom: 40px;
}

.task-form-assigned-to-container .task-form-assigned-to-container-inner,
.task-form-department-container .task-form-department-container-inner,
.task-form-due-date-container .task-form-due-date-container-inner,
.task-form-tag-container .task-form-tag-container-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
}

/* datepicker */
.react-aria-Button {
  background: #f5f6fa;
  color: #a1a7c4;
  border: 2px solid var(--brand-color);
  forced-color-adjust: none;
  border-radius: 4px;
  border: none;
  width: 1.429rem;
  height: 1.429rem;
  padding: 0;
  font-size: 0.857rem;
  box-sizing: content-box;
}

.react-aria-Button:hover {
  background-color: #a1a7c4;
  color: white;
}

.react-aria-Button[data-pressed] {
  box-shadow: none;
  background-color: var(--brand-color);
}

.react-aria-Button[data-focus-visible] {
  outline: 2px solid #a1a7c4;
  outline-offset: 2px;
}

.react-aria-Calendar {
  width: fit-content;
  max-width: 100%;
  color: black;
  background-color: #f5f6fa;
  padding: 32px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.react-aria-Calendar header {
  display: flex;
  align-items: center;
  margin: 0 4px 20px 4px;
}

.react-aria-Calendar header .react-aria-Heading {
  flex: 1;
  margin: 0;
  text-align: center;
  font-size: 30px;
}

.react-aria-Calendar .react-aria-Button {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.react-aria-Calendar .react-aria-CalendarGrid {
  font-size: 20px;
  text-align: center;
}

.react-aria-Calendar .react-aria-CalendarCell {
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 6px;
  cursor: default;
  outline: none;
  margin: 1px;
  forced-color-adjust: none;
  cursor: pointer;
}

.react-aria-Calendar .react-aria-CalendarCell[data-outside-month] {
  display: none;
}

.react-aria-Calendar .react-aria-CalendarCell[data-pressed] {
  background: gray;
  color: white;
}

.react-aria-Calendar .react-aria-CalendarCell[data-focus-visible] {
  outline: 2px solid var(--brand-color);
  outline-offset: 2px;
}

.react-aria-Calendar .react-aria-CalendarCell[data-selected] {
  background: var(--brand-color);
  color: white;
}
/* end datepicker */

.task-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 64px;
  gap: 32px;
}

.task-form-buttons-admin {
  display: flex;
  justify-content: space-around;
}

.task-form-buttons-create,
.task-form-buttons-update,
.task-form-buttons-cancel,
.task-form-buttons-admin-confirm-delete,
.task-form-buttons-admin-confirm-cancel {
  border-radius: 16px;
  color: white !important;
  padding: 24px 32px;
  border: none;
  width: 100%;
  cursor: pointer;

  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 500;
  line-height: 160%; /* 44.8px */
  letter-spacing: 2.5px;
}

.task-form-buttons-create,
.task-form-buttons-update {
  background-color: #007bff !important;
}

.task-form-buttons-cancel,
.task-form-buttons-admin-confirm-cancel {
  background-color: #5a607f !important;
}

.task-form-buttons-x-cancel {
  font-size: 33px;
  line-height: 50px;
  color: #7e84a3;
  cursor: pointer;
}

.task-form-buttons-cancel:hover {
  color: #131523;
}

.task-form-buttons-update {
  background-color: #007bff !important;
  color: white !important;
}

.task-form input,
.task-form textarea,
.task-form select {
  width: 100%;
  padding: 8px 24px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  margin: 0px 0px 40px 0px;
  display: inline-block;
  font-size: 28px;
  line-height: 160%;
  font-family: var(--font-family);
  background-color: #f5f6fa;
  color: #131523;
  box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25);
}

/* task edit */
.task-form-title-edit {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.task-form-buttons-admin {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.task-form-buttons-admin-archive,
.task-form-buttons-admin-delete {
  cursor: pointer;
  font-size: 33px;
  line-height: 50px;
  text-align: center;
}

.task-form-buttons-admin-archive {
  color: green;
}

.task-form-buttons-admin-delete {
  color: #dc143c;
}

.task-form-buttons-admin-confirm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.task-form-buttons-admin-confirm-delete,
.task-form-buttons-admin-confirm-cancel {
  color: white !important;
  width: auto !important;
}

.task-form-buttons-admin-confirm-delete {
  background-color: green !important;
}

.task-form-buttons-admin-confirm-text {
  text-align: center;
  padding-bottom: 64px;
}

.task-form-view-only-title {
  width: -webkit-fill-available;
  padding: 8px 24px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  margin: 0px 0px 40px 0px;
  display: inline-block;
  font-size: 28px;
  line-height: 160%;
  font-family: var(--font-family);
  /* text-decoration: none; */
  /* box-sizing: border-box; */
  background-color: #f5f6fa;
  color: #131523;
  box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25);
}

.task-form-view-only-description {
  width: -webkit-fill-available;
  height: 295px;
  padding: 8px 24px;
  border: 1px solid var(--brand-color);
  border-radius: 16px;
  margin: 0px 0px 40px 0px;
  display: inline-block;
  font-size: 28px;
  line-height: 160%;
  font-family: var(--font-family);
  background-color: #f5f6fa;
  color: #131523;
  box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25);
  overflow: auto;
  white-space: pre;
}

/* width */
.task-form-view-only-description::-webkit-scrollbar,
.task-form textarea::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.task-form-view-only-description::-webkit-scrollbar-track,
.task-form textarea::-webkit-scrollbar-track {
  background: #e6e9f4;
  border-radius: 16px;
  margin: 3px;
}

/* Handle */
.task-form-view-only-description::-webkit-scrollbar-thumb,
.task-form textarea::-webkit-scrollbar-thumb {
  background: #608dff;
  border-radius: 16px;
  cursor: initial;
}

/* Handle on hover */
.task-form-view-only-description::-webkit-scrollbar-thumb:hover,
.task-form textarea::-webkit-scrollbar-thumb:hover {
  background: #4f81ff;
}

@media (max-width: 1920px) {
  .task-form {
    /* background-color: #ffffff; */
    padding: 16px 16px 16px 16px;
    /* border-radius: 16px;   */
    /* box-shadow: 10px 10px 40px #00000069; */
    /* z-index: 1000; */
    /* position: absolute;   */
    /* transform: translate(-50%, -50%);     */
    width: 358px;
    /* text-align: left; */
  }

  /* .new-task-form-container {
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;  
    margin-top: 10px;  
  } */

  /* .edit-task-form-container {
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    margin-top: 10px;
  } */

  /* .task-form-background-overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  } */

  .task-form .error {
    /* margin-top: -20px; */
    margin-bottom: 30px;
    /* border-radius: 16px; */
    padding: 4px 18px;
  }

  /* .task-form .label {
    width: auto;
    margin: 0;
  } */

  /* .task-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  } */

  .task-form .task-form-title {
    font-size: 27px;
    /* line-height: 140%; */
    /* font-weight: 500; */
    /* letter-spacing: 0; */
    margin: 0px 0px 8px 0px;
  }

  .edit-task-form-container form,
  .task-form-view-only-container {
    margin-top: 32px;
  }

  .edit-task-form-container .task-form-audit,
  .edit-task-form-container .task-form-buttons-admin-confirm-text {
    /* font-family: var(--font-family); */
    font-size: 12px;
    /* font-style: normal; */
    /* font-weight: 400; */
    /* line-height: 160%; */
    /* letter-spacing: 0.8px; */
  }

  .task-form-department,
  .task-form-assigned-to,
  .task-form-due-date,
  .task-form-tag {
    /* display: flex; */
    padding: 6px 16px;
    /* align-items: center; */
    /* border-radius: 100px; */
    /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10); */
    /* width: fit-content; */

    /* color: #395030; */
    /* font-family: var(--font-family); */
    font-size: 12px;
    /* font-style: normal; */
    /* font-weight: 500; */
    /* line-height: 160%; */
    /* letter-spacing: 2.5px; */

    /* text-transform: uppercase; */
    /* transition: box-shadow 0.3s ease; */
  }

  .task-form-assigned-to-none {
    /* display: flex; */
    padding: 6px 16px;
    /* align-items: center;   */
    /* width: fit-content; */

    /* color: #395030; */
    /* font-family: var(--font-family); */
    font-size: 12px;
    /* font-style: normal; */
    /* font-weight: 500; */
    /* line-height: 160%; */
    /* letter-spacing: 2.5px; */

    /* text-transform: uppercase;   */
  }

  .task-form-tag-none {
    /* display: flex; */
    padding: 6px 16px;
    /* align-items: center;   */
    /* width: fit-content; */

    /* color: #395030; */
    /* font-family: var(--font-family); */
    font-size: 12px;
    /* font-style: normal; */
    /* font-weight: 500; */
    /* line-height: 160%; */
    /* letter-spacing: 2.5px; */

    /* text-transform: uppercase;   */
  }

  /* .task-form-department:hover, 
  .task-form-assigned-to:hover, 
  .task-form-due-date:hover {    
    box-shadow: none;  
    transition: box-shadow 0.3s ease;
  } */

  .task-form-assigned-to-selected,
  .task-form-department-selected,
  .task-form-due-date-selected,
  .task-form-tag-selected {
    /* background-color: #ECF2FF; */
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: space-between; */
    gap: 12px;
  }

  .task-form-assigned-to-selected .fa-xmark,
  .task-form-department-selected .fa-xmark,
  .task-form-due-date-selected .fa-xmark,
  .task-form-tag-selected .fa-xmark {
    /* color: white; */
    /* background: #333752; */
    font-size: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 3px;
    padding-right: 3px;
    /* border-radius: 50%;   */
    /* cursor: pointer; */
  }

  .task-form-assigned-to-container .fa-pen,
  .task-form-department-container .fa-pen,
  .task-form-due-date-container .fa-pen,
  .task-form-tag-container .fa-pen {
    /* color: #A1A7C4; */
    font-size: 15px;
    padding: 5px;
    /* border-radius: 8px; */
    /* border: 1px solid #A1A7C4; */
    /* cursor: pointer; */
  }

  /* .task-form-assigned-to-container .fa-pen.editing,
  .task-form-department-container .fa-pen.editing,
  .task-form-due-date-container .fa-pen.editing,
  .task-form-tag-container .fa-pen.editing {
    color: white;
    background-color: var(--brand-color);
    border: 1px solid var(--brand-color);
  } */

  .task-form-department-container {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 16px;
    /* color: #A1A7C4; */
    font-size: 24px;
    margin-bottom: 20px;
  }

  .task-form-assigned-to-container {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 16px;
    /* color: #A1A7C4; */
    font-size: 24px;
    margin-bottom: 20px;
  }

  .task-form-tag-container {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 25px;
    /* color: #A1A7C4; */
    font-size: 24px;
    margin-bottom: 20px;
  }

  .task-form-assigned-to-list,
  .task-form-department-list,
  .task-form-tag-list {
    /* position: absolute; */
    margin-left: 0px;
    bottom: 98px;
    width: 240px;
    /* flex-direction: column; */
    /* display: flex; */
    gap: 10px;
    padding: 12px 12px;
    /* border-radius: 16px; */
    /* border: 1px solid var(--brand-color); */
    /* background-color: #F5F6FA; */
    height: 160px;
    /* overflow: auto; */
    /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10); */
  }

  .task-form-due-date-list {
    /* position: absolute; */
    margin-left: -10px;
    bottom: 98px;
    /* border-radius: 16px; */
    /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10); */
  }

  /* width */
  /* .task-form-assigned-to-list::-webkit-scrollbar,
  .task-form-department-list::-webkit-scrollbar,
  .task-form-tag-list::-webkit-scrollbar {
    width: 20px;   
  } */

  /* Track */
  /* .task-form-assigned-to-list::-webkit-scrollbar-track,
  .task-form-department-list::-webkit-scrollbar-track,
  .task-form-tag-list::-webkit-scrollbar-track {
    background: #E6E9F4;
    border-radius: 16px;
    margin: 3px;  
  } */

  /* Handle */
  /* .task-form-assigned-to-list::-webkit-scrollbar-thumb,
  .task-form-department-list::-webkit-scrollbar-thumb,
  .task-form-tag-list::-webkit-scrollbar-thumb {
    background: #608DFF;
    border-radius: 16px;
  } */

  /* Handle on hover */
  /* .task-form-assigned-to-list::-webkit-scrollbar-thumb:hover,
  .task-form-department-list::-webkit-scrollbar-thumb:hover,
  .task-form-tag-list::-webkit-scrollbar-thumb:hover {
    background: #4F81FF;
  } */

  /* .task-form-assigned-to-list .task-form-assigned-to,
  .task-form-department-list .task-form-department,
  .task-form-tag-list .task-form-tag {
    cursor: pointer;
    background-color: white;
  } */

  /* .task-form-assigned-to-list .task-form-assigned-to.task-form-assigned-to-selected,
  .task-form-department-list .task-form-department.task-form-department-selected,
  .task-form-tag-list .task-form-tag.task-form-tag-selected {
    cursor: default;
    background-color: #ECF2FF;
  } */

  .task-form-due-date-container {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    gap: 16px;
    /* color: #A1A7C4; */
    font-size: 24px;
    margin-bottom: 20px;
  }

  .task-form-assigned-to-container .task-form-assigned-to-container-inner,
  .task-form-department-container .task-form-department-container-inner,
  .task-form-due-date-container .task-form-due-date-container-inner,
  .task-form-tag-container .task-form-tag-container-inner {
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: flex-start; */
    gap: 16px;
    /* justify-content: space-between; */
  }

  /* datepicker */
  /* .react-aria-Button {
    background: #F5F6FA;
    color: #A1A7C4;
    border: 2px solid var(--brand-color);
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;  
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    font-size: 0.857rem;
    box-sizing: content-box;
  } */

  /* .react-aria-Button:hover {
    background-color: #A1A7C4;
    color: white;
  } */

  /* .react-aria-Button[data-pressed] {
    box-shadow: none;
    background-color: var(--brand-color);
  } */

  /* .react-aria-Button[data-focus-visible] {
    outline: 2px solid #A1A7C4;
    outline-offset: 2px;
  } */

  /* .react-aria-DateInput, .react-aria-Input {
    display: none !important;
    
  } */

  /* .react-aria-Popover[data-trigger=DatePicker] {
    max-width: unset;
  } */

  .react-aria-Calendar {
    /* width: fit-content; */
    /* max-width: 100%; */
    /* color: black; */
    /* background-color: #F5F6FA; */
    padding: 16px;
    /* border: 1px solid var(--brand-color); */
    /* border-radius: 16px; */
    /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10); */
  }
  .react-aria-Calendar header {
    /* display: flex; */
    /* align-items: center; */
    margin: 0 2px 10px 2px;
  }

  .react-aria-Calendar header .react-aria-Heading {
    /* flex: 1; */
    /* margin: 0; */
    /* text-align: center; */
    font-size: 15px;
  }

  .react-aria-Calendar .react-aria-Button {
    width: 2rem;
    height: 2rem;
    /* padding: 0; */
  }

  .react-aria-Calendar .react-aria-CalendarGrid {
    font-size: 12px;
  }

  .react-aria-Calendar .react-aria-CalendarCell {
    width: 30px;
    line-height: 30px;
    /* text-align: center; */
    /* border-radius: 6px; */
    /* cursor: default; */
    /* outline: none; */
    /* margin: 1px; */
    /* forced-color-adjust: none; */
    /* cursor: pointer;   */
  }

  /* .react-aria-Calendar .react-aria-CalendarCell[data-outside-month] {
    display: none;
  } */

  /* .react-aria-Calendar .react-aria-CalendarCell[data-pressed] {
    background: gray;
    color: white;
  } */

  /* .react-aria-Calendar .react-aria-CalendarCell[data-focus-visible] {
    outline: 2px solid var(--brand-color);
    outline-offset: 2px;
  } */

  /* .react-aria-Calendar .react-aria-CalendarCell[data-selected] {
    background: var(--brand-color);
    color: white;
  } */
  /* end datepicker */

  .task-form-buttons {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-around; */
    margin-top: 32px;
    gap: 16px;
  }

  /* .task-form-buttons-admin {
    display: flex; 
    justify-content: space-around;
  } */

  .task-form-buttons-create,
  .task-form-buttons-update,
  .task-form-buttons-cancel,
  .task-form-buttons-admin-confirm-delete,
  .task-form-buttons-admin-confirm-cancel {
    /* border-radius: 16px;     */
    /* color: white !important; */
    padding: 12px 16px;
    /* border: none;   */
    /* width: 100%; */
    /* cursor: pointer; */

    /* font-family: var(--font-family); */
    font-size: 14px;
    /* font-weight: 500; */
    /* line-height: 160%; */
    /* letter-spacing: 2.5px; */
  }

  /* .task-form-buttons-create, .task-form-buttons-update {
    background-color: #007bff !important;
  } */

  /* .task-form-buttons-cancel, .task-form-buttons-admin-confirm-cancel {
    background-color: #5A607F !important;
  } */

  .task-form-buttons-x-cancel {
    font-size: 20px;
    line-height: 30px;
    /* color: #7E84A3; */
    /* cursor: pointer; */
  }

  /* .task-form-buttons-cancel:hover {  
    color: #131523;
  } */

  /* .task-form-buttons-update {
    background-color: #007bff !important;
    color: white !important;
  } */

  .task-form input,
  .task-form textarea,
  .task-form select {
    /* width: 100%; */
    padding: 4px 18px;
    /* border: 1px solid var(--brand-color); */
    /* border-radius: 16px; */
    margin: 0px 0px 20px 0px;
    /* display: inline-block; */
    font-size: 16px;
    /* line-height: 160%; */
    /* font-family: var(--font-family);   */
    /* background-color: #F5F6FA; */
    /* color: #131523; */
    /* box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25); */
  }

  /* task edit */
  /* .task-form-title-edit {  
    margin: 0;
    display: flex;
    justify-content: space-between;
  } */

  .task-form-buttons-admin {
    /* display: flex; */
    /* flex-direction: row; */
    /* gap: 16px; */
  }
  .task-form-buttons-admin-archive,
  .task-form-buttons-admin-delete {
    /* cursor: pointer; */
    font-size: 20px;
    line-height: 30px;
    /* text-align: center;   */
  }

  /* .task-form-buttons-admin-archive {      
    color: green;
  } */

  /* .task-form-buttons-admin-delete {      
    color: #dc143c;
  } */

  /* .task-form-buttons-admin-confirm {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  } */

  /* .task-form-buttons-admin-confirm-delete, .task-form-buttons-admin-confirm-cancel {
    color: white !important;
    width: auto !important;
  } */

  /* .task-form-buttons-admin-confirm-delete {
    background-color: green !important;  
  } */

  .task-form-buttons-admin-confirm-text {
    /* text-align: center; */
    padding-bottom: 32px;
  }

  .task-form-view-only-title {
    /* width: -webkit-fill-available; */
    padding: 4px 18px;
    /* border: 1px solid var(--brand-color); */
    /* border-radius: 16px; */
    margin: 0px 0px 20px 0px;
    /* display: inline-block; */
    font-size: 16px;
    /* line-height: 160%; */
    /* font-family: var(--font-family); */
    /* text-decoration: none; */
    /* box-sizing: border-box; */
    /* background-color: #F5F6FA; */
    /* color: #131523; */
    /* box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25); */
  }

  .task-form-view-only-description {
    /* width: -webkit-fill-available; */
    height: 150px;
    padding: 4px 18px;
    /* border: 1px solid var(--brand-color); */
    /* border-radius: 16px; */
    margin: 0px 0px 20px 0px;
    /* display: inline-block; */
    font-size: 16px;
    /* line-height: 160%; */
    /* font-family: var(--font-family);   */
    /* background-color: #F5F6FA; */
    /* color: #131523; */
    /* box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25); */
    /* overflow: auto; */
  }

  /* width */
  /* .task-form-view-only-description::-webkit-scrollbar,
  .task-form textarea::-webkit-scrollbar {
    width: 20px;   
  } */

  /* Track */
  /* .task-form-view-only-description::-webkit-scrollbar-track,
  .task-form textarea::-webkit-scrollbar-track {
    background: #E6E9F4;
    border-radius: 16px;
    margin: 3px;
  } */

  /* Handle */
  /* .task-form-view-only-description::-webkit-scrollbar-thumb,
  .task-form textarea::-webkit-scrollbar-thumb {
    background: #608DFF;
    border-radius: 16px;
    cursor: initial;
  } */

  /* Handle on hover */
  /* .task-form-view-only-description::-webkit-scrollbar-thumb:hover,
  .task-form textarea::-webkit-scrollbar-thumb:hover {
    background: #4F81FF;
  } */
}

.form-task-icon {
  width: 48px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-task-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0px;
}

.form-actions-edit {
  width: 48px; /* 
  height: 48px; */
  border-radius: 8px;
  border: 1px solid #a1a7c4;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* 
.form-actions-edit.editing {
  background: var(--brand-color);
  border-color: var(--brand-color);
} */

.form-actions-edit__image {
  width: 24px;
  height: 24px;
}

.form-actions-edit__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form-actions-edit.editing img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
    brightness(103%) contrast(103%);
}
.task-form button.form-actions-icon {
  border: none;
  background: transparent;
}

@media (max-width: 1920px) {
  /* .form-task-icon {
    width: 24px;
    height: 24px;
  } */

  .form-actions-edit {
    width: 27px;
    height: 27px;
    padding: 5px;
  }
  .form-task-icon {
    width: 28px;
  }
  /* .form-actions-edit__image {
    width: 12px;
    height: 12px;
  } */
}
