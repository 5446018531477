.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: #007FFF;
}

.login .container {
  max-width: 400px;
  flex: 1;
  min-height: unset;
  color: white;
}

.login .container a {
  color:aliceblue
}

.login .container .login-register-text, .login .container .login-text {
  text-align: center;
}

.login .container input {  
  background-color: white;
}

.login .container .tagline {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 200;
  font-size: 20px;
}

.login .container .logo {
  margin: 0;  
}

.login .fb {
  background-color: #3b5998;
  color: white;
  display: block;
  text-align: center;
}

.login .google {
  background-color: #dd4b39;
  color: white;
  display: block;
  text-align: center;
}

.login .login-text {
  /* display: inline-block; */
  /* min-width: 250px; */
  /* font-size: 20px; */
  text-align: center;
  margin: 15px;
}

.login .logins {
  margin: 5px 0;
}

.logo-login {
 height: 100px; 
}

.logo-login-container {
  text-align: center;
}
