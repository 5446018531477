/* .user-form input[type='file'] {
  display: none;
} */

/* .user-form input[type='file'] + label {
  display: inline-block;
  width: 100%;
  background-color: var(--general-general-30);
  border: 1px solid var(--primary-primary-100);
  box-shadow: 0px 5px 16px rgba(119, 133, 238, 0.3);
  border-radius: 16px;
  padding: 9.5px 24px;
  margin-bottom: 32px;
  cursor: pointer;
  position: relative;
  color: var(--general-general-100);
  transition: background-color 0.3s;
}

.user-form input[type='file'] + label:hover {
  background-color: var(--general-general-40);
} */

/* .user-form input[type='file'] + label:after {
  content: '+';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-primary-100);
  font-size: 20px;
  font-weight: bold;
}
 */

.user-form {
  grid-column: span 1;
  padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 1px #0000001a;
  border-radius: 32px;
}

.user-form h2 {
  color: var(--general-general-100);
  /* margin-bottom: 32px; */
}

.user-form .label {
  color: var(--general-general-100);
  margin-bottom: 20px;
}

.user-form input:not([type='submit']),
.user-form textarea {
  margin-bottom: 32px;
}

.user-form input:not([type='submit']),
.user-form select,
.user-form textarea {
  width: 100%;
  background-color: var(--general-general-30);
  border: 1px solid var(--primary-primary-100);
  box-shadow: 0px 5px 16px rgba(119, 133, 238, 0.3);
  border-radius: 16px;
  padding: 9.5px 24px;
  color: var(--general-general-100);
}

.user-form select {
  margin-bottom: 32px;
}

.user-form input:not([type='submit'])::placeholder {
  color: var(--general-gray);
}

.user-form-label {
  margin-bottom: 16px;
  color: var(--general-general-80);
}

.user__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.users__heading h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.submit-btn {
  margin-top: 32px;
  color: var(--general-general-30);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cancel-btn {
  background-color: var(--system-red-1);
}

.save-user {
  background-color: var(--system-green-1);
  /* margin-right: 32px; */
}

.submit-btn:hover,
.submit-btn:active {
  background-color: var(--general-general-70);
}

.user-form .error {
  display: inline-block;
  font-size: 32px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: transparent;
  border: 2px solid var(--system-red-1);
  border-radius: 16px;
  color: var(--system-red-1);
  margin-bottom: 8px;
  width: 100%;
  text-align: left;
}
.user-form .error:not(:last-of-type) {
  margin-bottom: 16px;
}
.user-form .error:first-of-type {
  margin-top: 36px;
}

.profile-page .avatar-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 36px;
  margin-bottom: 36px;  
} 

.profile-page .select-file-link {
  color: var(--primary-primary-70);  
  text-align: center;
  cursor: pointer;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 44.8px */
  letter-spacing: 0.2px;
}

.profile-page .drag-and-drop-text {
  color: #000;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 51.2px */
  letter-spacing: 0.2px;
}

.profile-page .or-text {
  color: var(--general-general-70);
  text-align: center;
  font-family: var(--default-font);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 44.8px */
  letter-spacing: 0.2px;  
}

.profile-page .drag-and-drop-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.profile-page .drag-and-drop-image {
  width: 100px;
  height: 100px;
  padding: 43px;
}

.profile-page .loading-spinner {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 


@media screen and (max-width: 1024.98px) {
  .user-form .error {
    font-size: 24px;
  }
  .user-form {
    /* width: 100%; */
  }

  .user-form .error:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .profile-page .avatar-section {
    /* display: flex; */
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 16px;
    /* margin-bottom: 36px;   */
  } 
  
  /* .profile-page .select-file-link {
    color: var(--primary-primary-70);  
    text-align: center;
    cursor: pointer;
    font-family: var(--default-font);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; 
    letter-spacing: 0.2px;
  } */
  
  .profile-page .drag-and-drop-text,
  .profile-page .or-text {
    display: none;
  }
  
  .profile-page .drag-and-drop-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  
  .profile-page .drag-and-drop-image {
    width: 70px;
    height: 70px;
    padding: 22px;
  }
}

@media screen and (max-width: 768px) {
  .user-form .error {
    font-size: 18px;
    padding: 6px 10px;
  }
  .user-form .error:not(:last-of-type) {
    margin-bottom: 8px;
  }
  .user-form {
    padding: 16px;
  }

  .submit-btn {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480.98px) {
  .profile-page .avatar-section {    
    flex-direction: column;        
  }
}