.name {
  font-size: 20px;
  font-weight: bold;
  min-width: 150px;
  height: 40px;
}
.username,
.time {
  font-size: 18px;
}

.department {
  border-bottom: 1px solid black;
  padding: 20px 0;
}
.department:last-child {
  border-bottom: 0;
}
.department .department-name {
  white-space: nowrap;
}
.department .avatar {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  /* border-radius: 50%; */
}

.department .department-header {
  display: flex;
  align-content: flex-start;
  margin-bottom: 20px;
}

.department p {
  margin: 0;
}

.department .btn {
  width: auto;
  margin-right: 10px;
}
.department-list-item {
  display: flex;
  flex-direction: row;
}
.department-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.department-row .department-color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 10px;
}
.department-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-info-container {
  display: flex;
  flex-direction: row;
}

.users-list-item {
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.users-list {
  margin: 10px 0;
  padding: 10px;
  background-color: #daebfc;
  border-radius: 5px;
}

.users-list-item:last-of-type {
  border-bottom: none;
  margin-bottom: 20px;
}

.user-name {
  font-style: italic;
}
.user-info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-width: 120px;
  margin-right: 20px;
}
