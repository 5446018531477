nav {
  background-color: var(--brand-color);
  padding-left: 30px;
  padding-right: 16px;
  height: var(--nav-bar-height);
}

.navbar {
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.logo {
  display: inline-block;
  color: white;
  margin: 0;
  white-space: nowrap;
  height: 120px;
  margin-left: 120px;
}

.tagLine {
  display: block;
  color: white;
  margin: 0;
  white-space: nowrap;
  font-size: 10px;
}

.nav-links {
  list-style: none;
  font-size: 20px;
  display: flex;
  margin: 5px 0;
}

.nav-item {
  display: inline-flex;
}

.nav-item a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  white-space: nowrap;
}

.nav-item:hover {
  background-color: white;
}

.nav-item:hover a {
  color: darkblue;
}

.flex-1 {
  flex: 1;
}
.navbar .avatar {
  width: 96px;
  height: 96px;
  /* align-self: center; */
  /* margin-right: 20px; */
  border-radius: 8px;
  background-color: #4f81ff;
  object-fit: cover;
}

.navbar .nav-button-icon, .project-view-page .nav-button-icon {
  margin-left: 16px;
}

.navbar .nav-button-divider, .project-view-page .nav-button-divider {
  width: 3px;
  height: 96px;
  margin-right: 16px;
  background-color: #4f81ff;
}

.nav-links-mobile {
  display: none;
  width: 100%;
  padding-right: 20px;
}

.nav-links-mobile-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.nav-links-mobile-container .rotate-icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.nav-links-mobile-menu {
  position: absolute;
  right: 0;
  background-color: #007fff;
  border-radius: 0 0 5px 5px;
  top: 60px;
}

.nav-links-mobile-menu ul {
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
}

.nav-links-mobile-menu li {
  padding: 10px 20px;
  border-top: solid 1px white;
}

.nav-links-mobile-menu li a {
  text-decoration: none;
  color: white;
}

.nav-links-mobile-icon {
  color: white;
  font-size: x-large;
  cursor: pointer;
}

/* @media (min-width: 1921px) { */
  .navbar {
    z-index: 4;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 46px);
  }
/* } */

@media (max-width: 1920px) {
  .logo {
    height: 60px;
    margin-left: 0px;
  }

  nav {
    height: var(--nav-bar-height-mobile);
    padding-left: 2px;
  }
  /* }

@media (max-width: 700px) {     */

  .navbar {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 2;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 18px);
    /* width: 100%; */
  }

  .nav-links-mobile {
    display: block;
  }

  .nav-links {
    display: none;
  }
}
