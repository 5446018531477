/* .dashboard-form .btn {
  width: auto;
}

.dashboard-form .label {
  width: auto;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard-form {
  min-width: 320px;
  max-width: 780px;
  margin-top: 76px;
  margin-bottom: 40px;
  background-color: #f5f5f5;
  padding: 0 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.dashboard-form-.form-select {
  width: 100%;
  padding: 12px;
  font-size: 17px;
  border-radius: 4px;
  line-height: 20px;
}
 */
.dashboard-form {
  /* flex: 1 1 30%; */

  /* width: 100%; */
  /* height: 100%; */
  grid-column: span 1;
  /* padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 32px; */
}
.project-list {
  padding: 32px;
  background-color: var(--primary-primary-30);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.dashboard-form h2 {
  color: var(--general-general-100);
  margin-bottom: 32px;
}

.dashboard-form .label {
  color: var(--General-General-80, #5a607f);

  margin-bottom: 20px;
}
/* .dashboard-form input:not([type='submit']) {
  margin-bottom: 64px;
} */
.dashboard-form input,
.dashboard-form .form-select {
  margin-bottom: 32px;
}
.dashboard-form input:not([type='submit']),
.dashboard-form .form-select {
  background-color: var(--general-general-30);
  border: 1px solid var(--primary-primary-100);
  box-shadow: 0px 5px 16px rgba(119, 133, 238, 0.3);
  border-radius: 16px;
  padding: 9.5px 24px;
  color: var(--general-general-100);
}
.form-section {
  margin-bottom: 22px;
}
.dashboard-form .form-select {
  width: 100%;
}
.dashboard-form input:not([type='submit'])::placeholder {
  color: var(--general-gray);
}
.dashboard-form-label {
  margin-bottom: 16px;
}
.dashboard-form .dashboard-form-label,
.dashboard-form .department-name,
.dashboard-form .user-username,
.dashboard-form .user-email {
  color: var(--general-general-80);
}
.dashboard-form .form-section .department-list-item,
.dashboard-form .form-section .user-list-item,
.profile-page .form-section .user-list-item {
  padding: 10px 0px 10px 24px;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between;   */
  /* width: 100%; */
}
.submit-btn {
  color: var(--general-general-30);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.project__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.archive__prompt {
  text-align: center;  
  margin: 32px 0 0 0;
  cursor: pointer;
}

.archive__prompt:hover {
  text-decoration: underline;
}

.cancel-btn {
  margin-top: 32px;

  width: 50%;
  background-color: var(--system-red-1);
}
.save-new-pod {
  margin-top: 32px;

  width: 50%;
  background-color: var(--system-green-1);
  margin-right: 32px;
}
.save-new-pod:only-child {
  margin-right: 0;
}
.submit-btn:hover,
.submit-btn:active {
  background-color: var(--general-general-70);
}
.form-section-gap {
  margin-bottom: 60px;
}
.form-section .department-list-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}
.department-list-item .department-row {
  /* flex: 1 1 auto; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.department-list-item .department-row .department-row-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cancel-btn-link .unique-btn {
  width: 100%;
  height: 100%;
}
.cancel-btn-link {
  color: var(--general-general-30);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.project-edit__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
  /* text-transform: uppercase; */
}
.project-edit__row h3.label {
  margin-bottom: 0;
}
.action-btn {
  border-radius: 8px;
}
.action-btn .department-btn__image {
  width: 28px;
  height: 28px;
}
.user-count {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  column-gap: 8px;
  color: var(--general-general-80);
}
.user-count span {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}
.user-count__icon {
  width: 32px;
  height: 32px;
}
.user-count__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.user-list-item {
  /* 
  margin-bottom: 20px; */
}
.user-info-container {
  display: flex;
  align-items: center;
  
}
button.user-list-link {
  border: 0; /* 
  padding: 0; */
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.user-title {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
}

.user-title .user-toggle__lead {
  cursor: pointer;
}



.user-title .user-toggle__lead.department-user-flag {
  color: var(--general-general-50);
}

.user-title .user-toggle__lead.department-lead-flag {
  color: var(--system-red-2);
}
.user-title .user-toggle__lead:hover {
  color: var(--danger-color);
}





.user-info__image {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  outline: 1px solid var(--primary-primary-100);
}
.user-info__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-info {
}
.user-username {
}
.user-email {
}
.department-list-link {
  border: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
}
.department-btn__image img {
  filter: var(--general-general-30-filter);
}
@media screen and (max-width: 1024.98px) {
  .form-section {
    margin-bottom: 12px;
  }
  .dashboard-form .form-section .department-list-item,
  .dashboard-form .form-section .user-list-item,
  .profile-page .form-section .user-list-item {
    padding: 10px 0px 10px 12px;
  }
  .dashboard-form input,
  .dashboard-form .form-select {
    margin-bottom: 22px;
  }
  /* .dashboard-form {
    width: 100%;
  } */
}
