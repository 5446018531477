.modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(215, 202, 202, 0.4); */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .modal {
  position: relative;

  border-radius: 16px;
  padding: 2rem;
  background-color: white;
  width: 25em;
}
.modal-form-due-date-container {
  margin-bottom: 0;
}

.modal .form-due-date-icon,
.modal .form-department-icon {
  width: 56px;
  height: 56px;
}

.modal-container .form-due-date-icon img,
.modal-container .form-department-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0;
}

.modal-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: clamp(30px, 20px, 10px);
}

.modal .modal-title {
  font-weight: bold;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: 0;
  color: rgba(51, 55, 82, 1);
}

.modal-title-close__svg {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(13%) saturate(343%) hue-rotate(191deg)
    brightness(87%) contrast(87%);
  transition: filter 0.3s ease;
}

.modal-title-close:hover .modal-title-close__svg {
  filter: brightness(0) saturate(100%) invert(31%) sepia(9%) saturate(1407%) hue-rotate(190deg)
    brightness(89%) contrast(88%);
}

.modal-container .modal .modal-btn {
  display: block;
  margin: auto;
  margin-top: 1rem;
  border: none;
  background-color: var(--main-color);
  cursor: pointer;
  color: #fff;
  padding: 1.3rem 2rem;
  font-size: 30px;
  box-shadow: 0px 5px 5px #ccc;
  border-radius: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.form-group input,
.form-group textarea,
.form-group select,
.form-group option {
  border-radius: 16px;
  border: 1px solid var(--primary-primary-100);
  background: var(--general-general-30);
  box-shadow: 0px 8px 16px 0px rgba(119, 132, 238, 0.3);

  padding: 0px 24px;
}

/* .form-group input,
.form-group textarea,
.form-group select,
.form-group option {
  border: 1px solid black;
  border-radius: 0.3rem;

  padding: 0.5rem;
  font-size: 1rem;

  width: auto;
  margin: none;
  opacity: 1;
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  text-decoration: none;
  box-sizing: border-box;
  background-color: #fff;
} */
.form-group option,
.form-group select {
  font-weight: bold;
}
.form-group label {
  margin-bottom: 0.2rem;
}

.error {
  background-color: #f8d7da;
  color: #df4759;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}
.modal input[type='checkbox'] {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.modal input[type='text'],
.modal input[type='number'],
.modal textarea {
  border-radius: 16px;
  border: 1px solid var(--brand-color);
  color: var(--general-general-100);
  padding: 8px 24px;
  background: #f5f6fa;
  box-shadow: 0px 6px 20px 0px rgba(119, 132, 238, 0.25);
}
/* 
.form-department-container,
 .form-due-date-container {
  display: flex;
  align-items: center;
  gap: 32px;
} */
/* 
.form-department-container-inner,
 .form-due-date-container-inner {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
} */
.task-form-department-container-inner.form-department-container-inner {
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* .form-department-selected {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  gap: 5px;
  border-radius: 100px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  font-weight: 300;
} */
/* .form-department,
 .form-due-date {
  cursor: pointer;
  color: var(--general-general-100);
  font-size: 20px;
  font-style: normal;
  line-height: 160%;
  letter-spacing: 2.5px;
  text-transform: uppercase;

  display: flex;
  padding: 12px 32px;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  width: fit-content;
  color: #395030;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease;
} */
.form-actions-edit__image {
  width: 40px;
  height: 40px;
}

/* .form-department-list,

.form-due-date-list {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
 */
.editing {
  color: var(--brand-color);
}

/* Calendar styles */
.Calendar {
  background: white;
  border: 1px solid #ccc;
  padding: 1rem;
}

.CalendarCell {
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.CalendarCell:hover {
  background: #f0f0f0;
}

.CalendarCell[aria-selected='true'] {
  background: var(--brand-color);
  color: white;
}

.remove-department-icon/*,
 .remove-due-date-icon */ {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  /* z-index: 0; */
  margin-left: 10px;
  display: flex;
  align-items: center;
  background-color: var(--general-general-90);
}
/* .remove-department-icon::after {
  content: '';
  width: 24px;
  position: absolute;
  height: 24px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-color: var(--general-general-90);
} */
.remove-department-icon img/* ,
.remove-due-date-icon img */ {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -45%);
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 80%;
  height: 80%;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(99%) sepia(6%) saturate(22%) hue-rotate(229deg)
    brightness(105%) contrast(100%);
}

.modal .form-department-selected {
  background-color: #ecf2ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 24px; */
  padding: 12px 32px;
  border-radius: 100px;

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  width: fit-content;
  color: #395030;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease;
}

.modal .form-department-list {
  /* position: absolute;
  margin-left: -14px;
  bottom: 191px;
  width: 480px;
  flex-direction: column;
  display: flex;
  gap: 20px;
  z-index: 2;
  padding: 24px 24px;
  border-radius: 16px;
  border: 1px solid var(--brand-color);
  background-color: #f5f6fa;
  height: 320px;
  overflow: auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1); */
  scrollbar-color: auto;
}

/* .form-department {
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}
 */
/* .form-department-list {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  min-width: 200px;
} */

/* .form-department-list .form-department {
  padding: 8px 16px;
  margin: 4px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
} */

/* .form-department-list .form-department:hover {
  opacity: 0.8;
}
 */
/* .form-department-list .form-department.form-department-selected {
  opacity: 0.6;
  cursor: not-allowed;
} */

/* .remove-department-icon {
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
 */
/* .form-department-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;  
}

.form-department-container-inner {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
} */
@media (max-width: 1920.98px) {
  .modal-container .modal .modal-btn {
    padding: 12px 16px;
    font-size: 22px;
  }
  .remove-department-icon {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1024px) {
}
