/* .fullname,
.username,
.time {
  font-size: 12px;
}

.project {
  padding: 20px 0;
  background-color: #007fff;
  color: white;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  min-width: 320px;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project:last-child {
  border-bottom: 0;
}

.project .avatar {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
}

.project .project-header {
  display: flex;
  align-content: flex-start;
  margin-bottom: 20px;
}

.project p {
  margin: 0;
}

.project .btn {
  width: auto;
  margin-right: 10px;
}

.project .label {
  width: auto;
  margin: 0;
}

.project .title {
  margin: 0;
}

.departments {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #c5e2ff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.project-actions {
  align-self: center;
}

.project-info {
  align-self: center;
  margin-right: 20px;
}

@media (max-width: 840px) {
  .project {
    flex-direction: column;
  }
  .project .title {
    text-align: center;
  }
}
 */

.project {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-primary-40);
  border-radius: 16px;
  padding: 53.5px 64px;
  column-gap: 12px;
}
.project:not(:last-child) {
  margin-bottom: 32px;
}

.project.selected {
  background: var(--Primary-Primary-50, #b6cbff);
}

.project-info {
  flex: 1 1 auto;
}
.project .title {
  margin: 0;
}
.project .fullname {
}
.project .time {
  letter-spacing: 2px;
  word-spacing: 3px;
}
.project .edited {
  color: var(--general-general-100);
}
.project-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .project-actions .btn:hover img,
.project-actions .btn:active img {
  filter: var(--primary-primary-40-filter);
} */

.project-info__meta span {
  text-transform: uppercase;
}
.project-info__meta .time {
  font-weight: 700;
}

/* .project-actions .project-actions__button {
  background-color: var(--primary-primary-80);
  margin: 0 24px 0 0;
} */

/* .project-actions .project-actions__button:only-child {
  margin: 0px 0px 0px 10px;
} */
.project-actions .btn-gray {
  margin: 0;
  background-color: var(--general-general-80);
}
.project-actions .project-actions__button .btn__image img {
  filter: var(--primary-primary-30-filter);
}
.project-actions .project-actions__button:hover,
.project-actions .project-actions__button:active {
  background-color: var(--primary-primary-70);
}
.btn__image {
  width: 56px;
  height: 56px;
}

@media screen and (max-width: 1920.98px) {
  .project {
    padding: 35px 40px;
  }
  .project-actions .btn__image {
    width: 38px;
    height: 38px;
  }
  /* .project-info h3 {
    font-size: 36px;
  }
  .project-info span {
    font-size: 20px;
  } */
}

@media screen and (max-width: 1024.98px) {
  .project {
    padding: 26px 30px;
  }

  /* .project-info h3 {
    font-size: 32px;
  }
  .project-info span {
    font-size: 18px;
  } */

  .btn__image {
    width: clamp(32px, 4vw, 52px);
    height: clamp(32px, 4vw, 52px);
  }
  /* 
  .project-actions .project-actions__button:only-child {
    margin: 0px 0px 0px 10px;
  } */
  .project-actions .project-actions__button.alone {
    margin: 0px 0px 0px 10px;
  }
  .project-actions .project-actions__button.alone {
    background-color: transparent;
    box-shadow: none;
  }
  .project-actions .alone {
    filter: var(--primary-primary-80-filter);
  }
  .project-actions .alone:hover img,
  .project-actions .alone:active img {
    filter: var(--primary-primary-30-filter);
  }
}
@media screen and (max-width: 768.98px) {
  .project {
    padding: 24px 32px;
    flex-direction: column;
    gap: 20px;
  }
  .project-actions {
    justify-content: space-around;
  }
  .project-actions .btn__image {
    width: 32px;
    height: 32px;
  }
  .project-actions a .project-actions__button,
  .project-actions a {
    height: 100%;
  }
  .project-actions a {
    display: block;
  }
  .project-actions .project-actions__button {
    background-color: transparent;

    filter: var(--primary-primary-80-filter);
    box-shadow: none;
  }
  .project-actions .project-actions__button {
    margin-right: 5px;
  }
  .project-actions .project-actions__button:hover,
  .project-actions .project-actions__button:active {
    background-color: transparent;
  }
}
@media screen and (max-width: 480.98px) {
  /*.project {
    flex-direction: column;
  }  
  .project-actions {
    justify-content: center;
  } */
}
