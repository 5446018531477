/* TEAM PULSE CHECK */

.tpc_actions {
  width: 10%;
  text-align: center;
}

.tpc_question {
  width: 70%;
  text-align: center;
}

.tpc_rating {
  width: 20%;
  text-align: center;
}
.huddle-board-content-row.team-pulse-check-container {
  margin-top: 50px;
  gap: 50px;
  align-items: flex-start;
  /* margin-bottom: 350px; */
}
.huddle-board-content-row.team-pulse-check-container .huddle-board-content-metric {
  width: 700px;
}
.team-pulse-check .table-wrapper {
  width: 100%;
  padding: 0;
}
.team-pulse-check .table {    
  height: 893px;
}

.team-pulse-check .table tbody {
  height: 817px;
}

.team-pulse-check .table tbody tr td .rating-btn.svg-inline--fa.rating-1,
.team-pulse-check .table tbody tr td .rating-btn.svg-inline--fa.rating-2,
.team-pulse-check .table tbody tr td .rating-btn.svg-inline--fa.rating-3,
.team-pulse-check .table tbody tr td .rating-btn.svg-inline--fa.rating-4,
.team-pulse-check .table tbody tr td .rating-btn.svg-inline--fa.rating-5 {
  color: goldenrod;
}

.team-pulse-check .table tbody tr td .svg-inline--fa {
  cursor: pointer;
  color: var(--gray-color);
}

.team-pulse-check .table tbody tr td.tpc_actions .svg-inline--fa:hover {
  cursor: pointer;
  color: var(--black-color);
}

.team-pulse-check .table tbody tr td.tpc_rating .svg-inline--fa:hover {
  cursor: pointer;
  color: var(--black-color);  
}

.team-pulse-check .table tbody tr.rating-5 {
  background-color: #D6E082;
}

.team-pulse-check .table tbody tr.rating-4 {
  background-color: #E7E483;
}

.team-pulse-check .table tbody tr.rating-3 {
  background-color: #F7E984;
}

.team-pulse-check .table tbody tr.rating-2 {
  background-color: #FEE482;
}

.team-pulse-check .table tbody tr.rating-1 {
  background-color: #FDD680;
}

.team-pulse-check-deep-dive {
  margin-top: 20px;
  position: fixed;
  margin-right: 100px;
}

.team-pulse-check-btn-container {
  /* width: 80%; */
  margin-left: auto;
  margin-right: auto;
}

.team-pulse-check-btn-container .btn-add {
  width: auto;
  height: 70px;
  border-radius: 35px;
  background-color: var(--dark-accept-color);
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  font-family: var(--default-font);
}

.team-pulse-check-deep-dive-table {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  margin-left: 120px;
  margin-right: 120px;
  align-items: flex-start;    
  color: #222;
  
}

.team-pulse-check-deep-dive-table .deep-dive-row {
  width: 20%;
  /* border: 1px solid white;     */
  border-left: 1px solid #ccc;
  height: 250px;
  border-collapse: collapse;
}

.team-pulse-check-deep-dive-table .deep-dive-row .deep-dive-heading {
  background-color: #ccc;
  padding: 10px;    
}

.team-pulse-check-deep-dive-table .deep-dive-row .deep-dive-content {
  padding: 10px;
}

.team-pulse-check-deep-dive-table .team-pulse-check-deep-dive-edit-form {
  display: inherit;
  width: 90%;
  min-width: 50vw;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.team-pulse-check-deep-dive-table .deep-dive-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;    
  font-weight: 400;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;        
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit input, .team-pulse-check-deep-dive-table .deep-dive-content-edit textarea {
  font-weight: 400;
  background-color: white;
  color: #222;
  font-family: var(--default-font);
  opacity: 1;
  font-size: 1em;
  padding: 0px;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit textarea {
  border-radius: 8px;
  border: none;
  background-color: white;
  line-height: 1.5em;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit .edit-form-buttons {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: 50px;    
  gap: 40px;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit .edit-form-buttons .svg-inline--fa {
  font-size: 2.5em;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit .edit-form-buttons .edit-submit-button:hover {
  opacity: 1;
}

.team-pulse-check-deep-dive-table .deep-dive-content-edit .edit-form-buttons .edit-submit-button {    
  background-color: unset;    
  border: 0px;
  cursor: pointer;
  opacity: 0.1;
}

.team-pulse-check .global-edit-indicator, .attendance .global-edit-indicator {
  margin-top: 20px;
  margin-bottom: -20px;
  font-size: 0.7em;
  font-weight: 400;
  color: var(--black-color);
}
/* END TEAM PULSE CHECK */



/* SPONSOR REPORT */

.sponsor-report-form.form__content {
  margin: 20px 0;
}

.sponsor-report-form .form__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-report-form .form__table td,
.sponsor-report-form .form__table th {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

.sponsor-report-form .form__table td {
  height: 214px;
}

.sponsor-report-form .form__table th {
  background: #f5f5f5;
  font-weight: 500;
  color: var(--black-color);
}

.sponsor-report-form .form__table tr:nth-child(even) {
  background: #fafafa;
}

.sponsor-report-form .form__table tr:hover {
  background: #f0f0f0;
}

.sponsor-report-form .form {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.sponsor-report-form textarea {
  overflow: hidden;
  background-color: transparent;
  /* height: 30px; */
  border: none;
  font-size: 32px;
  line-height: 120%;
  font-weight: 400;
  font-family: var(--default-font);
  background-color: white;
}
/* .sponsor-report-form p {
  overflow: hidden;
  background-color: transparent;
  font-size: 32px;
} */

.sponsor-report-form .input-row-controls {  
  margin-top: -60px;
  position: relative;
  z-index: 1;
}
.sponsor-report-form .descriptor {
  width: 25%;
  font-weight: 700;
  color: var(--black-color);
}
.sponsor-report-form .button {
}

.sponsor-report-form .status-button {
  padding: 30px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  /* transition: all 0.1s; */
  font-size: 0.9em;
  font-weight: 500;
}
.sponsor-report-form .status-button {
  position: relative;
}

.sponsor-report-form .status-button::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #000000;
  opacity: 0;
  /* transition: opacity 0.1s ease; */
}

.sponsor-report-form .status-button:hover::after,
.sponsor-report-form .status-button:active::after,
.sponsor-report-form .status-button.clicked::after {
  opacity: 1;
}

.sponsor-report-form .status-button:hover,
.sponsor-report-form .status-button:active {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-report-form .status-button:not(:last-child) {
  margin-right: 10px;
}
.sponsor-report-form .status-button:nth-child(1) {
  background-color: var(--accept-color);
}

.sponsor-report-form .status-button:nth-child(2) {
  background-color: var(--yellow-color);
}

.sponsor-report-form .status-button:nth-child(3) {
  background-color: var(--danger-color);
}

.sponsor-report-form .input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
}

.sponsor-report-form .input-edit-icon {
  color: var(--black-color);
  opacity: 0.1;
  cursor: pointer;
  transition: opacity .5s ease-in-out .1s;
}
.sponsor-report-form .input-edit-icon:hover {
  opacity: 0.9;
  transition: opacity .5s ease-in-out .1s;
}
.sponsor-report-form .edit-submit-button {
  background: transparent;
  color: var(--accept-color);
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sponsor-report-form .edit-controls {
  display: flex;
  gap: 8px;
  font-size: 30px;
  align-items: center;
}

.sponsor-report-form .edit-cancel-button {
  background: transparent;
  color: var(--danger-color);
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sponsor-report-form .error {
  color: #f44336;
  font-size: 0.9em;
  margin-top: 4px;
}

@media (max-width: 1024.98px) {
  .sponsor-report-form input {
    font-size: 22px;
  }
  .sponsor-report-form p {
    font-size: 22px;
  }
  .sponsor-report-form .descriptor,
  .sponsor-report-form th {
    font-size: 24px;
  }
}
/* END SPONSOR REPORT */



/* WEEKLY PRIORITIES */

.wp_priority {
  width: 10%;  
}

.wp_description {
  width: 70%;  
}

.wp_status {
  width: 20%;  
}

.weekly-priorities-form.form__content {
  margin: 20px 0;
}

.weekly-priorities-form .form__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.weekly-priorities-form .form__table td,
.weekly-priorities-form .form__table th {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: center;
}

.weekly-priorities-form .form__table td {
  height: 300px;
}

.weekly-priorities-form .form__table th {
  background: #f5f5f5;
  font-weight: 500;
  color: var(--black-color);
}

.weekly-priorities-form .form__table tr:nth-child(even) {
  background: #fafafa;
}

.weekly-priorities-form .form__table tr:hover {
  background: #f0f0f0;
}

.weekly-priorities-form .form {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.weekly-priorities-form textarea {
  overflow: hidden;
  background-color: transparent;
  /* height: 30px; */
  border: none;
  font-size: 48px;
  line-height: 120%;
  font-weight: 400;
  font-family: var(--default-font);
  background-color: white;
  text-align: center;
}

.weekly-priorities-form .input-row-controls {  
  margin-top: -60px;
  position: relative;
  z-index: 1;
}
.weekly-priorities-form .descriptor {
  font-weight: 700;
  color: var(--black-color);
  font-size: 48px;
}
.weekly-priorities-form .button {
}

.weekly-priorities-form .status-button {
  padding: 30px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  /* transition: all 0.1s; */
  font-size: 0.9em;
  font-weight: 500;
}
.weekly-priorities-form .status-button {
  position: relative;
}

.weekly-priorities-form .status-button::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #000000;
  opacity: 0;
  /* transition: opacity 0.1s ease; */
}

.weekly-priorities-form .status-button:hover::after,
.weekly-priorities-form .status-button:active::after,
.weekly-priorities-form .status-button.clicked::after {
  opacity: 1;
}

.weekly-priorities-form .status-button:hover,
.weekly-priorities-form .status-button:active {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.weekly-priorities-form .status-button:not(:last-child) {
  margin-right: 10px;
}

.weekly-priorities-form .status-button:nth-child(1) {
  background-color: var(--dark-accept-color);
}

.weekly-priorities-form .status-button:nth-child(1)::after {  
  color: #ffffff;
  font-weight: 900;  
}

.weekly-priorities-form .status-button:nth-child(2) {
  background-color: var(--accept-color);
}

.weekly-priorities-form .status-button:nth-child(3) {
  background-color: var(--yellow-color);
}

.weekly-priorities-form .status-button:nth-child(4) {
  background-color: var(--danger-color);
}

.weekly-priorities-form .priority-status-row.dark-accept {
  background-color: #1FCE537d !important;  
}

.weekly-priorities-form .priority-status-row.accept {
  background-color: #5BF98A7d !important;    
}

.weekly-priorities-form .priority-status-row.yellow {
  background-color: #ffdd007d !important;  
}

.weekly-priorities-form .priority-status-row.danger {
  background-color: #FF70437d !important;    
}

.weekly-priorities-form .input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 48px;
  font-weight: 400;
  justify-content: center;
}

.weekly-priorities-form .input-edit-icon {
  color: var(--black-color);
  font-size: 32px;
  opacity: 0.1;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out .1s;
}
.weekly-priorities-form .input-edit-icon:hover {
  opacity: 0.9;
  transition: opacity 0.5s ease-in-out .1s;
}
.weekly-priorities-form .edit-submit-button {
  background: transparent;
  color: var(--accept-color);
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.weekly-priorities-form .edit-controls {
  display: flex;
  gap: 8px;
  font-size: 30px;
  align-items: center;
}

.weekly-priorities-form .edit-cancel-button {
  background: transparent;
  color: var(--danger-color);
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.weekly-priorities-form .error {
  color: #f44336;
  font-size: 0.9em;
  margin-top: 4px;
}

@media (max-width: 1024.98px) {
  .weekly-priorities-form input {
    font-size: 22px;
  }
  .weekly-priorities-form p {
    font-size: 22px;
  }
  .weekly-priorities-form .descriptor,
  .weekly-priorities-form th {
    font-size: 24px;
  }
}
/* END WEEKLY PRIORITIES */